import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function EmpresasTable({ empresas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEmpresa, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    
    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del empresa si se ha proporcionado un filtro
    const empresasFiltradas = filtroEmpresa
        ? empresas.filter(empresa => empresa.nombre_corto_empresa.toLowerCase().includes(filtroEmpresa.toLowerCase()))
        : empresas;

    const paginatedTransactions = empresasFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_empresas')
                .delete()
                .eq('id_empresa', id);

            if (error) {
                throw new Error('No se pudo borrar la empresa');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'empresa eliminada',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el empresa',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((empresa) => (
        <tr key={empresa.id_empresa}>
            <td>{empresa.id_empresa}</td>

            <td>{truncateText(empresa.nombre_corto_empresa ? empresa.nombre_corto_empresa : '',35)}</td>
            <td>{truncateText(empresa.nombre_oficial_empresa, 30)}</td>
            <td>{truncateText(empresa.rfc_empresa, 14)}</td>
            <td>{truncateText(empresa.direccion_empresa, 30)}</td>




            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_empresas &&
                        permisos.catalogos.modulos.cat_empresas.acciones &&
                        permisos.catalogos.modulos.cat_empresas.acciones.modificar_empresa && (
                            <i
                                key={`edit-${empresa.id_empresa}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(empresa);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_empresas &&
                        permisos.catalogos.modulos.cat_empresas.acciones &&
                        permisos.catalogos.modulos.cat_empresas.acciones.eliminar_empresa && (
                            <i
                                key={`delete-${empresa.id_empresa}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este empresa?')) {
                                        deleteTransaction(empresa.id_empresa);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre empresa',
    ];

    const csvData = empresas.map((empresa) => [
        empresa.id_empresa,
        empresa.nombre_corto_empresa,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre corto</th>
                        <th>Nombre oficial</th>
                        <th>RFC</th>
                        <th>Dirección</th>


                        
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(empresas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'empresas.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default EmpresasTable;
