import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, TextInput, Textarea, Select, Group, Badge, Spoiler, Text, Timeline } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

import { useForm } from '@mantine/form';
import { supabase } from '../supabaseClient';
import moment from 'moment';
import ActividadesFiltro from './ActividadesFiltro';
import { IconSpy, IconPhone, IconCalendar, IconId, IconBrandZoom, IconEdit, IconTrash, IconMail, IconBrandWhatsapp, IconNote } from '@tabler/icons-react';

const ActividadesTable = ({ tratoId }) => {
    const [actividades, setActividades] = useState([]);
    const [estatusActividades, setEstatusActividades] = useState([]);
    const [filtroActividades, setFiltroActividades] = useState({
        fecha: '',
        nombre: '',
        descripcion: '',
        estatus: '',
    });
    const [editingActivity, setEditingActivity] = useState(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [view, setView] = useState('cards');

    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    // Función para convertir una cadena de fecha en un objeto Date
    const convertToDate = (fecha) => {
        if (!fecha) return null; // Manejo del caso cuando `fecha` es null o undefined

        // Crear un objeto Date a partir de la cadena de fecha
        return new Date(fecha);
    };

    useEffect(() => {
        const fetchActividades = async () => {
            const { data, error } = await supabase
                .from('cat_actividades')
                .select('*')
                .eq('id_trato', tratoId)
                .order('id_actividad', { ascending: false });
            if (error) {
                console.error(error);
            } else {
                setActividades(data);
            }
        };
        fetchActividades();
    }, [tratoId]);

    useEffect(() => {
        const fetchEstatusActividades = async () => {
            const { data, error } = await supabase
                .from('cat_estatus_actividades')
                .select('*');
            if (error) {
                console.error(error);
            } else {
                setEstatusActividades(data);
            }
        };
        fetchEstatusActividades();
    }, []);

    const handleEditActivity = (activity) => {
        setEditingActivity(activity);
        form.setValues({
            nombre_actividad: activity.nombre_actividad,
            descripcion_actividad: activity.descripcion_actividad,
            id_estatus_actividad: activity.id_estatus_actividad.toString(),
            fecha_actividad: moment(activity.fecha_actividad).format('YYYY-MM-DDTHH:mm:ss'), // Incluye la hora
            tipo_actividad: activity.tipo_actividad?.toString() || '',  // Aseguramos la conversión a string
        });
        setEditModalOpen(true);
    };

    const handleDeleteActivity = async (activityId) => {
        const { error } = await supabase
            .from('cat_actividades')
            .delete()
            .eq('id_actividad', activityId);
        if (error) {
            console.error(error);
        } else {
            setActividades(actividades.filter(activity => activity.id_actividad !== activityId));
        }
    };

    const form = useForm({
        initialValues: {
            nombre_actividad: '',
            descripcion_actividad: '',
            id_estatus_actividad: '',
            fecha_actividad: new Date(),
            tipo_actividad: '',
        },
    });

    const handleUpdateActivity = async (values) => {
        // Obtener la fecha y hora seleccionada del formulario o cualquier fuente
        const fechaActividad = new Date(values.fecha_actividad);

        // Convertir la fecha a la zona horaria de Ciudad de México
        const fechaCDMX = new Date(fechaActividad.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }));

        // Formatear la fecha y hora para que coincida con el formato de timestamp esperado por Supabase
        const fechaFormateada = fechaCDMX.toISOString().slice(0, 19).replace('T', ' ');

        // Actualizar el valor en el objeto values
        values.fecha_actividad = fechaFormateada;


        // Realizar la actualización en la base de datos usando Supabase
        const { data, error } = await supabase
            .from('cat_actividades')
            .update(values)
            .eq('id_actividad', editingActivity.id_actividad)
            .select();
        //console.log('Valores enviados:', values);

        if (error) {
            console.error(error);
        } else if (data && data.length > 0) {
            const updatedActivities = actividades.map(activity =>
                activity.id_actividad === editingActivity.id_actividad ? data[0] : activity
            ).sort((a, b) => new Date(b.fecha_actividad) - new Date(a.fecha_actividad));
            setActividades(updatedActivities);
            setEditModalOpen(false);
        }
    };

    const handleAddActivity = async (values) => {
        const { data, error } = await supabase
            .from('cat_actividades')
            .insert({ ...values, id_trato: tratoId })
            .select();
        if (error) {
            console.error(error);
        } else {
            const updatedActivities = [...actividades, data[0]].sort((a, b) => new Date(b.fecha_actividad) - new Date(a.fecha_actividad));
            setActividades(updatedActivities);
            setAddModalOpen(false);
            form.reset(); // revisar si es necesario

        }
    };

    const openAddModal = () => {
        form.reset(); // Resetear el formulario antes de abrir el modal
        setAddModalOpen(true);
    };

    const handleFilterActividades = async (filtro) => {
        try {
            setFiltroActividades(filtro); // Actualizar estado de filtro

            let query = supabase.from('cat_actividades').select('*').eq('id_trato', tratoId);



            console.log('Filtro recibido:', filtro);

            if (filtro.fechainicial) {
                const fechaFiltroInicial = new Date(filtro.fechainicial);
                // No se necesita ajustar la hora
                console.log('Fecha inicial:', fechaFiltroInicial.toISOString()+6);
                query = query.gte('fecha_actividad', fechaFiltroInicial.toISOString());
            }

            if (filtro.fechafinal) {
                const fechaFiltroFinal = new Date(filtro.fechafinal);
                // No se necesita ajustar la hora
                console.log('Fecha final:', fechaFiltroFinal.toISOString()+6);
                query = query.lte('fecha_actividad', fechaFiltroFinal.toISOString());
            }


            if (filtro.nombre) {
                query = query.ilike('nombre_actividad', `%${filtro.nombre}%`);
            }
            if (filtro.descripcion) {
                query = query.ilike('descripcion_actividad', `%${filtro.descripcion}%`);
            }

            // Aplicar filtro de estatus si no es "Todos"
            if (filtro.estatus !== '') {
                query = query.eq('id_estatus_actividad', filtro.estatus);
            }

            const { data, error } = await query.order('fecha_actividad', { ascending: false });

            if (error) {
                throw error;
            }

            setActividades(data);
        } catch (error) {
            console.error('Error al filtrar actividades:', error.message);
        }
    };

    const getIconByTipoActividad = (tipo) => {
        const tipoStr = tipo.toString();
        switch (tipoStr) {
            case '1':
                return <IconMail size={20} />;
            case '2':
                return <IconBrandWhatsapp size={20} />;
            case '3':
                return <IconNote size={20} />;
            case '4':
                return <IconSpy size={20} />;
            case '5':
                return <IconBrandZoom size={20} />;
            case '6':
                return <IconPhone size={20} />;
            default:
                return <IconNote size={20} />;
        }
    };


    return (
        <>
            <ActividadesFiltro onFilter={handleFilterActividades} estatusActividades={estatusActividades} />

            <Group mb={'15px'}>
                <Button.Group>
                    <Button color='black' variant={view === 'table' ? 'filled' : 'outline'} onClick={() => setView('table')}>Tabla</Button>
                    <Button color='black' variant={view === 'cards' ? 'filled' : 'outline'} onClick={() => setView('cards')}>Línea de tiempo</Button>
                </Button.Group>
                <Button onClick={openAddModal} className='boton-negro' >Agregar Actividad</Button>
            </Group>

            <div style={{ overflowX: "auto" }}>
                {view === 'cards' && (
                    <>

                        <Timeline bulletSize={40}>
                            {actividades.map((actividad, index) => (
                                <Timeline.Item
                                    key={index}
                                    lineVariant="dashed"
                                    bullet={getIconByTipoActividad(actividad.tipo_actividad)}
                                    style={{ marginBottom: '30px' }}
                                >
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                        {actividad.nombre_actividad}
                                    </div>
                                    <Group>
                                        {actividad.id_estatus_actividad === 1 ? (
                                            <Badge color="green" variant="filled">
                                                Completada
                                            </Badge>
                                        ) : actividad.id_estatus_actividad === 2 ? (
                                            <Badge color="red" variant="filled">
                                                Pendiente
                                            </Badge>
                                        ) : (
                                            <Badge color="blue" variant="filled">
                                                {estatusActividades.find(e => e.id_estatus_actividad === actividad.id_estatus_actividad)?.nombre_estatus_actividad}
                                            </Badge>
                                        )}
                                        <Text color="dimmed" size="sm">
                                            <IconId size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            ID: {actividad.id_actividad}
                                        </Text>
                                        <Text color="dimmed" size="sm">
                                            <IconCalendar size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            {moment(actividad.fecha_actividad).format('LLL')}
                                        </Text>
                                        <IconEdit
                                            size={20}
                                            onClick={() => handleEditActivity(actividad)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <IconTrash

                                            size={20}
                                            onClick={() => {
                                                if (window.confirm('¿Estás seguro de eliminar esta actividad?')) {
                                                    handleDeleteActivity(actividad.id_actividad);
                                                }
                                            }} style={{ cursor: 'pointer' }}
                                        />
                                    </Group>

                                    <Spoiler maxHeight={100} showLabel="Leer más" hideLabel="Ver menos" transitionDuration={500}>
                                        <Text size='sm' mt={4} style={{ whiteSpace: 'pre-line' }}>
                                            {actividad.descripcion_actividad}</Text>
                                    </Spoiler>
                                </Timeline.Item>
                            ))}
                        </Timeline>


                    </>
                )}

                {view === 'table' && (
                    <Table striped highlightOnHover withBorder withColumnBorders>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Estatus</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {actividades.map((actividad) => (
                                <tr key={actividad.id_actividad}>
                                    <td>{moment(actividad.fecha_actividad).format('LL')}</td>
                                    <td>{actividad.nombre_actividad}</td>
                                    <td>{actividad.descripcion_actividad}</td>
                                    <td>
                                        {actividad.id_estatus_actividad === 1 ? (
                                            <Badge color="green" variant="filled">
                                                Completada
                                            </Badge>
                                        ) : actividad.id_estatus_actividad === 2 ? (
                                            <Badge color="red" variant="filled">
                                                Pendiente
                                            </Badge>
                                        ) : (
                                            estatusActividades.find(e => e.id_estatus_actividad === actividad.id_estatus_actividad)?.nombre_estatus_actividad
                                        )}
                                    </td>
                                    <td>
                                        <Group justify="center">
                                            <IconEdit size={20} onClick={() => handleEditActivity(actividad)} style={{ cursor: 'pointer' }} />
                                            <IconTrash size={20} onClick={() => {
                                                if (window.confirm('¿Estás seguro de eliminar esta actividad?')) {
                                                    handleDeleteActivity(actividad.id_actividad);
                                                }
                                            }} style={{ cursor: 'pointer' }} />
                                        </Group>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>

            <Modal
                size="auto"

                opened={isEditModalOpen}
                onClose={() => setEditModalOpen(false)}
                title="Editar Actividad"
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>

                    <form onSubmit={form.onSubmit((values) => handleUpdateActivity(values))}>
                        <Group>
                            <DateTimePicker
                                label="Fecha y hora"
                                placeholder="Selecciona fecha y hora"
                                className='caja-150'
                                value={convertToDate(form.values.fecha_actividad)}
                                onChange={(date) => form.setFieldValue('fecha_actividad', date)}
                            />

                            <TextInput
                                label="Nombre de la Actividad"
                                placeholder="Nombre de la Actividad"
                                className='caja-400'
                                {...form.getInputProps('nombre_actividad')}
                            />
                            <Select
                                label="Estatus"
                                placeholder="Seleccione un estatus"
                                className='caja-150'
                                data={estatusActividades.map(estatus => ({
                                    value: estatus.id_estatus_actividad.toString(),
                                    label: estatus.nombre_estatus_actividad,
                                }))}
                                {...form.getInputProps('id_estatus_actividad')}
                            />
                            <Select
                                label="Tipo de Actividad"
                                placeholder="Seleccione un tipo de actividad"
                                className='caja-150'
                                data={[
                                    { value: '1', label: 'Correo' },
                                    { value: '2', label: 'WhatsApp' },
                                    { value: '3', label: 'Nota' },
                                    { value: '4', label: 'Reunión presencial' },
                                    { value: '5', label: 'Videollamada' },
                                    { value: '6', label: 'Llamada telefónica' },



                                ]}
                                {...form.getInputProps('tipo_actividad')}
                            />
                        </Group>
                        <Group>
                            <Textarea
                                label="Descripción de la Actividad"
                                placeholder="Descripción de la Actividad"
                                styles={{
                                    input: {
                                        backgroundColor: '#FCF3C2' // Color amarillo
                                    }
                                }}
                                className='caja-notas'
                                autosize
                                minRows={4}
                                {...form.getInputProps('descripcion_actividad')}
                            />
                        </Group>



                        <Group position="right" mt="md">
                            <Button type="submit" className='boton-negro'>Guardar cambios</Button>
                        </Group>
                    </form>
                </div>
            </Modal>

            <Modal
                size="auto"

                opened={isAddModalOpen}
                onClose={() => setAddModalOpen(false)}
                title="Agregar Actividad"
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>

                    <form onSubmit={form.onSubmit((values) => handleAddActivity(values))}>
                        <Group>
                            <DateTimePicker
                                label="Fecha y Hora de la Actividad"
                                placeholder="Selecciona fecha y hora"
                                className='caja-150'
                                {...form.getInputProps('fecha_actividad')}
                            />
                            <TextInput
                                label="Nombre de la Actividad"
                                placeholder="Nombre de la Actividad"
                                className='caja-400'
                                {...form.getInputProps('nombre_actividad')}
                            />
                            <Select
                                label="Estatus"
                                placeholder="Seleccione un estatus"
                                className='caja-150'
                                data={estatusActividades.map(estatus => ({
                                    value: estatus.id_estatus_actividad.toString(),
                                    label: estatus.nombre_estatus_actividad,
                                }))}
                                {...form.getInputProps('id_estatus_actividad')}
                            />
                            <Select
                                label="Tipo de Actividad"
                                placeholder="Seleccione un tipo de actividad"
                                className='caja-150'
                                data={[
                                    { value: '1', label: 'Correo' },
                                    { value: '2', label: 'WhatsApp' },
                                    { value: '3', label: 'Nota' },
                                    { value: '4', label: 'Reunión presencial' },
                                    { value: '5', label: 'Videollamada' },
                                    { value: '6', label: 'Llamada telefónica' },

                                ]}
                                {...form.getInputProps('tipo_actividad')}
                            />
                        </Group>
                        <Group>
                            <Textarea
                                label="Descripción de la Actividad"
                                placeholder="Descripción de la Actividad"
                                styles={{
                                    input: {
                                        backgroundColor: '#FCF3C2' // Color amarillo
                                    }
                                }}
                                className='caja-notas'
                                autosize
                                minRows={4}
                                {...form.getInputProps('descripcion_actividad')}
                            />
                        </Group>
                        <Group position="right" mt="md">
                            <Button type="submit" className='boton-negro'>Agregar actividad</Button>
                        </Group>
                    </form>
                </div>

            </Modal>
        </>
    );
};

export default ActividadesTable;
