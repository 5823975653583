import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CsvDownloader from 'react-csv-downloader';
import { Button, Group, Text } from '@mantine/core';

const localizer = momentLocalizer(moment);

function ActividadesCalendario({ actividades, setSelectedTransaction, setFormMode, setShowForm, getData, filtroActividad, user, permisos }) {
    const dispatch = useDispatch();

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_actividades')
                .delete()
                .eq('id_actividad', id);

            if (error) {
                throw new Error('No se pudo borrar la actividad');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Actividad eliminada',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la actividad',
                color: 'red'
            });
        }
    };

    const events = actividades.map((actividad) => ({
        id: actividad.id_actividad,
        title: actividad.nombre_actividad,
        start: new Date(actividad.fecha_actividad),
        end: new Date(actividad.fecha_actividad), // Assuming the activity has no duration info
        allDay: false,
        desc: actividad.descripcion_actividad,
        estatus: actividad.cat_estatus_actividades.nombre_estatus_actividad,
    }));

    const handleSelectEvent = (event) => {
        setSelectedTransaction(event);
        setFormMode("edit");
        setShowForm(true);
    };

    const eventPropGetter = (event) => {
        const backgroundColor = event.estatus === 'Completada' ? 'green' : 'red';
        return { style: { backgroundColor } };
    };

    const csvHeaders = [
        'ID',
        'Nombre actividad',
    ];

    const csvData = actividades.map((actividad) => [
        actividad.id_actividad,
        actividad.nombre_actividad,
    ]);

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventPropGetter}
                messages={{
                    next: "Sig",
                    previous: "Ant",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    agenda: "Agenda",
                    date: "Fecha",
                    time: "Hora",
                    event: "Evento",
                }}
            />
            <Group justify="flex-end" mb={10} mt={20}>
                <CsvDownloader
                    filename={'actividades.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Datos</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ActividadesCalendario;
