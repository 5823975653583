import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import FuentesTable from '../components/FuentesTable';
import FuenteForm from '../components/FuenteForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";




function CatalogoFuentes({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [fuentes, setFuentes] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroFuente, setFiltroFuente] = useState('');
   


    useEffect(() => {
        //body
        try {
            const getTiposContratantes = async () => {
                dispatch(ShowLoading());
                const allTiposContratantes = await fetchTiposContratantes()
                setFuentes(allTiposContratantes) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getTiposContratantes()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchTiposContratantes = async () => {
        try {
            let { data: fuentes, error } = await supabase
                .from('cat_fuentes')
                .select(`
                id_fuente,
                nombre_fuente
                
                `)
                .order('id_fuente', { ascending: true })
            //console.log("Fuentes obtenidos:", fuentes); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return fuentes;
        } catch (error) {
            //console.error('Error al obtener fuentes:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: fuentesFiltradas, error } = await supabase
                .from('cat_fuentes')
                .select(`
                id_fuente,
                nombre_fuente
                
                `)
                .ilike('nombre_fuente', `%${filtroFuente.toLowerCase()}%`)
                .order('id_fuente', { ascending: true });

            if (error) {
                throw error;
            }
            setFuentes(fuentesFiltradas);
        } catch (error) {
            //console.error('Error al filtrar fuentes:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allContratantes = await fetchTiposContratantes();
            setFuentes(allContratantes);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddFuente = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_fuentes &&
        permisos.catalogos.modulos.cat_fuentes.acciones &&
        permisos.catalogos.modulos.cat_fuentes.acciones.registrar_fuente === true;


    return (
        <div>
            
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar fuente"
                                value={filtroFuente}
                                onChange={(event) => setFiltroFuente(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddFuente && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Fuente
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Fuente" : "Editar Fuente"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <FuenteForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar fuente seleccionada como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>

                    <FuentesTable
                        fuentes={fuentes}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoFuentes;