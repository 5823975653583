import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, Textarea } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";



function EmpresasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    //const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        id_empresa: "",
        nombre_corto_empresa: "",
        nombre_oficial_empresa: "",
        rfc_empresa: "",
        direccion_empresa: "",
        cp_empresa: "",
        notas_empresa: "",

    })

    // Este useEffect es porque en CatalogoContratante ya tomo el valor de id_tipo_contratante, entonces
    // este codigo evita que lo ponga en "" y me marque un warning
    useEffect(() => {
        if (!user.id_empresa) {
            setUser({
                id_empresa: "",
                nombre_corto_empresa: "",
                nombre_oficial_empresa: "",
                rfc_empresa: "",
                direccion_empresa: "",
                cp_empresa: "",
                notas_empresa: "",



            });
        }
    }, [user.id_empresa]);




    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({

                id_empresa: transactionData.id_empresa || "",
                nombre_corto_empresa: transactionData.nombre_corto_empresa || "",
                nombre_oficial_empresa: transactionData.nombre_oficial_empresa || "",
                rfc_empresa: transactionData.rfc_empresa || "",
                direccion_empresa: transactionData.direccion_empresa || "",
                cp_empresa: transactionData.cp_empresa || "",
                notas_empresa: transactionData.notas_empresa || "",

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };



    const handleUpdate = async () => {

        try {
            await supabase
                .from('cat_empresas')
                .update({
                    nombre_corto_empresa: user.nombre_corto_empresa,
                    nombre_oficial_empresa: user.nombre_oficial_empresa,
                    rfc_empresa: user.rfc_empresa,
                    direccion_empresa: user.direccion_empresa,
                    cp_empresa: user.cp_empresa,
                    notas_empresa: user.notas_empresa,
                })
                .eq('id_empresa', user.id_empresa); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Empresa actualizado',
                message: 'La Empresa se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el Empresa:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el Empresa.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('cat_empresas')
                    .insert({
                        nombre_corto_empresa: user.nombre_corto_empresa,
                        nombre_oficial_empresa: user.nombre_oficial_empresa,
                        rfc_empresa: user.rfc_empresa,
                        direccion_empresa: user.direccion_empresa,
                        cp_empresa: user.cp_empresa

                    });
                showNotification({
                    title: 'Empresa agregada',
                    message: 'La Empresa ha sido agregada con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdate();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            console.error('Error al agregar/actualizar Empresa:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar Empresa.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <Group >

                            <TextInput
                                disabled
                                name="id_empresa"
                                label="ID"
                                onChange={handleChange}
                                className="caja-100"
                                value={user.id_empresa}

                            />

                            <TextInput

                                name="rfc_empresa"
                                label="RFC"
                                onChange={handleChange}
                                className="caja-150"
                                value={user.rfc_empresa}

                            />
                            <TextInput
                                name="nombre_corto_empresa"
                                label="Nombre Empresa"
                                required
                                className="caja-350"

                                onChange={handleChange}
                                value={user.nombre_corto_empresa} // Asignar el valor del estado al campo
                                placeholder='Empresa'
                            />

                        </Group>

                        <Group className="group-no-wrap">
                            
                            <TextInput
                                name="nombre_oficial_empresa"
                                label="Nombre oficial empresa"
                                className="caja-80p"

                                onChange={handleChange}
                                value={user.nombre_oficial_empresa}

                            />
                            <TextInput
                                name="cp_empresa"
                                label="CP"
                                onChange={handleChange}
                                className="caja-20p"
                                value={user.cp_empresa}
                            />

                        </Group>


                        <Group >

                            
                            <TextInput
                                name="direccion_empresa"
                                label="Dirección empresa"
                                onChange={handleChange}
                                className="caja-600"
                                value={user.direccion_empresa}
                            />
                        </Group>
                        <Group>
                            <Textarea
                                name="notas_empresa"
                                label="Notas empresa"
                                onChange={handleChange}
                                className="textarea-fullwidth"
                                value={user.notas_empresa}
                            />

                        </Group>
                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} className='boton-verde' type="submit">
                        {formMode === 'add' ? "+ Agregar Empresa" : "Editar Empresa"}
                    </Button>
                </div>
            </form>

        </div>
    )
}

export default EmpresasForm;