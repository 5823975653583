import { MantineProvider } from '@mantine/core';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot
import { BrowserRouter } from 'react-router-dom';

import '@mantine/core/styles.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// para spinner

import { Provider } from 'react-redux';
import store from './redux/store'; // Importa tu store Redux
import { Notifications } from "@mantine/notifications";

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withCssVariables>
        <Notifications position="top-center" />
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
