import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import ActividadesTable from '../components/ActividadesTable2';
import ActividadesTable3 from '../components/ActividadesTable3';
import ActividadesFiltro from '../components/ActividadesFiltro2';
import ActividadesForm from '../components/ActividadesForm2';
import { Group, Button, Modal, Card, Accordion, } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import ActividadesCalendario from '../components/ActividadesCalendario';

function CatalogoActividades({ user, permisos, idTrato }) {
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [actividades, setActividades] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [filters, setFilters] = useState({
        fechaInicial: null,
        fechaFinal: null,
        nombre_actividad: "",
        descripcion_actividad: "",
        nombre_estatus_actividad: "",
        nombre_trato: "",
        nombre_corto_empresa: "",
        nombre_categoria_trato: "",
        nombre_estado_trato: "",
        nombre_etapa_trato: "",
    });
    const dispatch = useDispatch();
    const [view, setView] = useState("cards");

    // para popular el filtro empresa y trato cuando abro desde modal de tratos
    useEffect(() => {
        if (idTrato) {
            // Obtener detalles del trato
            const fetchTratoDetails = async () => {
                try {
                    const { data, error } = await supabase
                        .from('cat_tratos')
                        .select(`
                            nombre_trato,
                            cat_empresas!inner(nombre_corto_empresa)
                        `)
                        .eq('id_trato', idTrato)
                        .single();

                    if (error) {
                        throw error;
                    }
                    console.log("Data fetched:", data); // Log para inspeccionar datos


                    setFilters(prevFilters => ({
                        ...prevFilters,
                        nombre_trato: data.nombre_trato,
                        nombre_corto_empresa: data.cat_empresas.nombre_corto_empresa
                    }));
                } catch (error) {
                    console.error('Error fetching trato details:', error);
                }
            };

            fetchTratoDetails();
        }
    }, [idTrato]);

    const formatFecha = (fecha) => {
        if (!fecha) return null;
        const fechaActividad = new Date(fecha);
        const fechaCDMX = new Date(fechaActividad.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }));
        return fechaCDMX.toISOString().slice(0, 19).replace('T', ' ');
    };

    /*
    useEffect(() => {
        const getActividades = async () => {
            try {
                dispatch(ShowLoading());
                const allActividades = await fetchActividades();
                setActividades(allActividades);
                dispatch(HideLoading());
            } catch (error) {
                console.error('Error:', error);
                dispatch(HideLoading());
            }
        };
        getActividades();
    }, []);
    */

    const fetchActividades = async () => {
        try {
            let supabaseQuery = supabase
                .from('cat_actividades')
                .select(`
                    id_actividad,
                    nombre_actividad,
                    descripcion_actividad,
                    fecha_actividad,
                    id_tipo_actividad,
                    id_trato,
                    cat_tratos!inner(
                        id_trato, 
                        nombre_trato,
                        descripcion_trato,
                        id_categoria_trato,
                        cat_categorias_tratos!inner(
                            id_categoria_trato,
                            nombre_categoria_trato
                            ),
                        id_estado_trato,
                        cat_estados_tratos!inner(
                            id_estado_trato,
                            nombre_estado_trato
                            ),
                        id_etapa_trato,
                        cat_etapas_tratos!inner(
                            id_etapa_trato,
                            nombre_etapa_trato
                            ),
                        id_empresa,
                        cat_empresas!inner(
                            id_empresa,
                            nombre_corto_empresa
                        )
                    ),
                    id_estatus_actividad,
                    cat_estatus_actividades!inner(
                        id_estatus_actividad, 
                        nombre_estatus_actividad
                    ),
                    id_tipo_actividad,
                    cat_tipos_actividades!inner(
                        id_tipo_actividad,
                        nombre_tipo_actividad
                    )

                `)
                .order('fecha_actividad', { ascending: false });
            // Filtrar por idTrato si está definido
            if (idTrato) {
                supabaseQuery = supabaseQuery.eq('id_trato', idTrato);
            }


            if (filters.nombre_trato) {
                supabaseQuery = supabaseQuery.eq('cat_tratos.nombre_trato', filters.nombre_trato);
            }

            if (filters.nombre_categoria_trato) {
                supabaseQuery = supabaseQuery.eq('cat_tratos.cat_categorias_tratos.nombre_categoria_trato', filters.nombre_categoria_trato);
            }

            if (filters.nombre_estado_trato) {
                supabaseQuery = supabaseQuery.eq('cat_tratos.cat_estados_tratos.nombre_estado_trato', filters.nombre_estado_trato);
            }

            if (filters.nombre_etapa_trato) {
                supabaseQuery = supabaseQuery.eq('cat_tratos.cat_etapas_tratos.nombre_etapa_trato', filters.nombre_etapa_trato);
            }

            if (filters.nombre_corto_empresa) {
                supabaseQuery = supabaseQuery.eq('cat_tratos.cat_empresas.nombre_corto_empresa', filters.nombre_corto_empresa);
            }

            if (filters.nombre_estatus_actividad) {
                supabaseQuery = supabaseQuery.eq('cat_estatus_actividades.nombre_estatus_actividad', filters.nombre_estatus_actividad);
            }

            if (filters.nombre_actividad) {
                supabaseQuery = supabaseQuery.ilike('nombre_actividad', `%${filters.nombre_actividad}%`);
            }

            if (filters.descripcion_actividad) {
                supabaseQuery = supabaseQuery.ilike('descripcion_actividad', `%${filters.descripcion_actividad}%`);
            }

            if (filters.fechaInicial) {
                const fechaInicialFormateada = formatFecha(filters.fechaInicial);
                supabaseQuery = supabaseQuery.gte('fecha_actividad', fechaInicialFormateada);
            }

            if (filters.fechaFinal) {
                const fechaFinalFormateada = formatFecha(filters.fechaFinal);
                supabaseQuery = supabaseQuery.lt('fecha_actividad', fechaFinalFormateada);
            }

            const { data: actividades, error } = await supabaseQuery;
            console.log("Actividades...", actividades);

            if (error) {
                throw error;
            }

            return actividades;
        } catch (error) {
            console.error('Error al obtener actividades:', error.message);
            throw error;
        }
    };


    const getData = async () => {
        try {
            dispatch(ShowLoading());
            const allActividades = await fetchActividades();
            setActividades(allActividades);
            dispatch(HideLoading());
        } catch (error) {
            console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red",
            });
            dispatch(HideLoading());
        }
    };

    const canAddActividad = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_actividades &&
        permisos.catalogos.modulos.cat_actividades.acciones &&
        permisos.catalogos.modulos.cat_actividades.acciones.registrar_actividad === true;
    // Depuración: Verifica los permisos recibidos
    console.log('Permisos:', permisos);
    // Depuración: Verifica si canAddActividad es verdadero
    console.log('Puede agregar actividad:', canAddActividad);

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <ActividadesFiltro
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                            isTratoDisabled={!!idTrato}
                            isEmpresaDisabled={!!idTrato}
                        />
                        <Group mt={'20px'}>
                            <Button leftSection={<IconSearch size={20} />} className='boton-morado' onClick={getData}>
                                Buscar Actividades
                            </Button>

                            <Button.Group >
                                <Button
                                    className={view === 'cards' ? 'boton-morado' : 'boton-morado-outline'}
                                    variant={view === 'cards' ? 'filled' : 'outline'}
                                    onClick={() => setView('cards')}
                                >
                                    Tarjetas
                                </Button>
                                <Button
                                    className={view === 'table' ? 'boton-morado' : 'boton-morado-outline'}
                                    variant={view === 'table' ? 'filled' : 'outline'}
                                    onClick={() => setView('table')}
                                >
                                    Tabla
                                </Button>
                                <Button
                                    className={view === 'calendar' ? 'boton-morado' : 'boton-morado-outline'}
                                    variant={view === 'calendar' ? 'filled' : 'outline'}
                                    onClick={() => setView('calendar')}
                                >
                                    Calendario
                                </Button>
                            </Button.Group>



                            {canAddActividad && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}>
                                    + Agregar Actividad
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    //size="auto"
                    fullScreen
                    title={formMode === "add" ? "Agregar Actividad" : "Editar Actividad"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ActividadesForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    {view === 'cards' && <ActividadesTable3
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'table' && <ActividadesTable
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'calendar' && <ActividadesCalendario
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                </div>
            </Card>
        </div>
    );
}

export default CatalogoActividades;
