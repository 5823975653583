import React, { useState } from 'react';
import { supabase } from "../supabaseClient";
import TratosForm from '../components/TratosForm';
import TratosTable from '../components/TratosTable';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import TratosFiltros from '../components/TratosFiltros';
import { IconSearch } from '@tabler/icons-react';
import TratosKanban from '../components/TratosKanban';

function CatalogoTratos({ user, permisos }) {
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [tratos, setTratos] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({
        nombre_trato: "",
        nombre_fuente: "",
        nombre_estado_trato: "",
        nombre_etapa_trato:"",
        nombre_categoria_trato:"",
        nombre_corto_empresa:"",
        nombre_contacto:"",
    });

    const [view, setView] = useState('cards');


    const fetchTratos = async () => {
        try {
            dispatch(ShowLoading());
            let supabaseQuery = supabase.from('cat_tratos').select(`
                id_trato,
                nombre_trato,
                valor_trato,
                fecha_trato_ganado,
                fecha_trato_abierto,
                fecha_trato_perdido,
                fecha_trato_declinado,
                descripcion_trato,
                id_fuente,
                cat_fuentes!inner (
                    id_fuente,
                    nombre_fuente
                    ),
                id_estado_trato,
                cat_estados_tratos!inner (
                    id_estado_trato,
                    nombre_estado_trato
                    ),
                id_etapa_trato,
                cat_etapas_tratos!inner (
                    id_etapa_trato,
                    nombre_etapa_trato
                ),
                id_categoria_trato,
                cat_categorias_tratos!inner (
                    id_categoria_trato,
                    nombre_categoria_trato
                ),
                id_empresa,
                cat_empresas !inner(id_empresa, nombre_corto_empresa),
                cat_tratos_contactos!inner (
                    id_contacto, 
                    cat_contactos!inner (
                        id_contacto, 
                        nombre_contacto, 
                        correo_contacto, 
                        celular_contacto,
                        id_empresa,
                        cat_empresas!inner (nombre_corto_empresa)
                    )
                )
            `);

            if (filters.nombre_trato) {
                supabaseQuery = supabaseQuery.eq('nombre_trato', filters.nombre_trato);
            }

            if (filters.nombre_fuente) {
                supabaseQuery = supabaseQuery.eq('cat_fuentes.nombre_fuente', filters.nombre_fuente);
            }

            if (filters.nombre_estado_trato) {
                supabaseQuery = supabaseQuery.eq('cat_estados_tratos.nombre_estado_trato', filters.nombre_estado_trato);
            }

            if (filters.nombre_categoria_trato) {
                supabaseQuery = supabaseQuery.eq('cat_categorias_tratos.nombre_categoria_trato', filters.nombre_categoria_trato);
            }

            if (filters.nombre_etapa_trato) {
                supabaseQuery = supabaseQuery.eq('cat_etapas_tratos.nombre_etapa_trato', filters.nombre_etapa_trato);
            }

            if (filters.nombre_corto_empresa) {
                supabaseQuery = supabaseQuery.eq('cat_empresas.nombre_corto_empresa', filters.nombre_corto_empresa);
            }

            if (filters.nombre_contacto) {
                supabaseQuery = supabaseQuery.ilike('cat_tratos_contactos.cat_contactos.nombre_contacto', `%${filters.nombre_contacto}%`);
            }


            const { data: tratos, error } = await supabaseQuery.order('id_trato', { ascending: false });

            if (error) {
                throw error;
            }

            const formattedTratos = tratos.map(trato => ({
                ...trato,
                contactos: trato.cat_tratos_contactos.map(tc => ({
                    id_contacto: String(tc.cat_contactos.id_contacto), // Asegúrate de que sea una cadena
                    nombre_contacto: tc.cat_contactos.nombre_contacto,
                    correo_contacto: tc.cat_contactos.correo_contacto,
                    celular_contacto: tc.cat_contactos.celular_contacto,
                    nombre_corto_empresa: tc.cat_contactos.cat_empresas ? tc.cat_contactos.cat_empresas.nombre_corto_empresa : null,
                }))
            }));

            setTratos(formattedTratos); // Actualiza el estado con los datos formateados
            //console.log('Tratos formateados', formattedTratos);





        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red"
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const getData = () => {
        fetchTratos();
    };

    const canAddTrato = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_tratos &&
        permisos.catalogos.modulos.cat_tratos.acciones &&
        permisos.catalogos.modulos.cat_tratos.acciones.registrar_trato === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <TratosFiltros
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                        />
                        <Group mt={'20px'}>

                            <Button leftSection={<IconSearch size={20} />} className='boton-morado' onClick={getData}>
                                Buscar Tratos
                            </Button>

                            {canAddTrato && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}
                                >
                                    + Agregar Trato
                                </Button>
                            )}

                            <Button.Group >
                                <Button
                                    className={view === 'cards' ? 'boton-morado' : 'boton-morado-outline'}
                                    variant={view === 'cards' ? 'filled' : 'outline'}
                                    onClick={() => setView('cards')}
                                >
                                    Ver Tarjetas
                                </Button>
                                <Button
                                    className={view === 'table' ? 'boton-morado' : 'boton-morado-outline'}
                                    variant={view === 'table' ? 'filled' : 'outline'}
                                    onClick={() => setView('table')}
                                >
                                    Ver Tabla
                                </Button>

                            </Button.Group>

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    fullScreen
                    title={formMode === "add" ? "Agregar Trato" : "Editar Trato"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                    styles={{
                        body: {
                            backgroundColor: '#f0f0f0',
                        },
                        inner: {
                            backgroundColor: '#f0f0f0',
                        },
                        header: {
                            backgroundColor: '#32323A',
                            color: 'white'
                        },
                        close: {
                            color: '#ffffff',
                        },
                    }}
                >
                    <TratosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                        user={user}
                        permisos={permisos}
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    {view === 'table' && <TratosTable
                        tratos={tratos}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'cards' && <TratosKanban
                        tratos={tratos}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                </div>
            </Card>
        </div>
    );
}

export default CatalogoTratos;
