import React, { useState, useEffect } from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { supabase } from '../supabaseClient';

const BarChartTratosGanados = ({ filtro }) => {
  const [data, setData] = useState([]);
  const [tratos, setTratos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!filtro) return;

      const { categoriaSeleccionada, fechaInicio, fechaFin } = filtro;

      const fechaInicioISO = fechaInicio.toISOString();
      const fechaFinISO = fechaFin.toISOString();

      let { data: tratos, error } = await supabase
        .from('cat_tratos')
        .select('id_trato, nombre_trato, fecha_trato_ganado, valor_trato, id_estado_trato')
        .eq('id_estado_trato', 2) // Cambiamos a 1 en lugar de 'Ganado'
        .eq('id_categoria_trato', categoriaSeleccionada)
        .gte('fecha_trato_ganado', fechaInicioISO)
        .lte('fecha_trato_ganado', fechaFinISO);

      if (error) {
        console.error('Error fetching tratos:', error);
        return;
      }

      // Transform the data for the chart
      const chartData = tratos.reduce((acc, trato) => {
        const date = new Date(trato.fecha_trato_ganado);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const key = `${year}-${String(date.getMonth() + 1).padStart(2, '0')}`; // `YYYY-MM` format
        if (!acc[key]) {
          acc[key] = { key, year, month, count: 0, valor_total: 0 };
        }
        acc[key].count += 1;
        acc[key].valor_total += trato.valor_trato;
        return acc;
      }, {});

      // Sort data by year and month
      const sortedData = Object.values(chartData).sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year;
        }
        return parseInt(a.key.split('-')[1], 10) - parseInt(b.key.split('-')[1], 10);
      });

      setData(sortedData);
      setTratos(tratos);
    };

    fetchData();
  }, [filtro]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="key"
            tickFormatter={(value) => {
              const [year, month] = value.split('-');
              return `${new Date(`${year}-${month}`).toLocaleString('default', { month: 'long' })} ${year}`;
            }}
          />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="count" fill="#8884d8" />
          <Line yAxisId="right" type="monotone" dataKey="valor_total" stroke="#82ca9d" strokeWidth={5} />
        </ComposedChart>
      </ResponsiveContainer>

      <div style={{ marginTop: 20 }}>
        <h3>Detalles de Tratos</h3>
        <table border="1" cellPadding="10" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>ID Trato</th>
              <th>Nombre Trato</th>
              <th>Valor Trato</th>
              <th>ID Estado Trato</th>
              <th>Fecha Trato Ganado</th>
            </tr>
          </thead>
          <tbody>
            {tratos.map((trato) => (
              <tr key={trato.id_trato}>
                <td>{trato.id_trato}</td>
                <td>{trato.nombre_trato}</td>
                <td>{trato.valor_trato}</td>
                <td>{trato.id_estado_trato}</td>
                <td>{new Date(trato.fecha_trato_ganado).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BarChartTratosGanados;
