import React, { useEffect, useState } from 'react';
import { Card, TextInput, Group, Button, NativeSelect, Textarea } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { DateTimePicker } from '@mantine/dates';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function ActividadesFormResumido({ getData, idTrato, idActividad, setShowForm, setFormMode }) {
    const dispatch = useDispatch();

    // Valores iniciales
    const [user, setUser] = useState({
        id_actividad: "",
        nombre_actividad: "",
        descripcion_actividad: "",
        fecha_actividad: new Date(),
        id_estatus_actividad: "", //el valor inicial de estatus activida se establece en el 2do useEffect
        id_tipo_actividad: "",
    });

    // Función para convertir una cadena de fecha en un objeto Date
    const convertToDate = (fecha) => {
        if (!fecha) return null; // Manejo del caso cuando `fecha` es null o undefined
        return new Date(fecha);
    };

    // Listas de opciones
    const [listadoestatusactividad, setListadoestatusactividades] = useState([]);
    const [listadotiposactividades, setListadotiposactividades] = useState([]);

    // Cargar opciones al montar el componente
    useEffect(() => {
        async function fetchEstatusActividades() {
            try {
                const { data: estatus } = await supabase
                    .from('cat_estatus_actividades')
                    .select('id_estatus_actividad, nombre_estatus_actividad')
                    .order('id_estatus_actividad', { ascending: true });

                setListadoestatusactividades(estatus || []);
            } catch (error) {
                console.error('Error fetching estatus actividades:', error);
            }
        }

        async function fetchTiposActividades() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_tipos_actividades')
                    .select('id_tipo_actividad, nombre_tipo_actividad')
                    .order('nombre_tipo_actividad', { ascending: true });
                setListadotiposactividades(tipos || []);
            } catch (error) {
                console.error('Error fetching tipos actividades:', error);
            }
        }

        fetchEstatusActividades();
        fetchTiposActividades();
    }, []);

    // Cargar los datos de la actividad cuando se edita
    useEffect(() => {
        if (idActividad) {
            async function fetchActividad() {
                try {
                    const { data, error } = await supabase
                        .from('cat_actividades')
                        .select('*')
                        .eq('id_actividad', idActividad)
                        .single();

                    if (error) throw error;

                    setUser({
                        id_actividad: data.id_actividad,
                        nombre_actividad: data.nombre_actividad,
                        descripcion_actividad: data.descripcion_actividad,
                        fecha_actividad: data.fecha_actividad,
                        id_estatus_actividad: data.id_estatus_actividad,
                        id_tipo_actividad: data.id_tipo_actividad,
                    });
                } catch (error) {
                    console.error('Error fetching actividad:', error);
                }
            }

            fetchActividad();
        } else {
            // Reset the form if no idActividad is provided (for creating a new activity)
            setUser({
                id_actividad: "",
                nombre_actividad: "",
                descripcion_actividad: "",
                fecha_actividad: new Date(),
                id_estatus_actividad: "2",
                id_tipo_actividad: "3",
            });
        }
    }, [idActividad]);

    // Manejar cambios en los campos del formulario
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // Manejar la actualización o adición de la actividad
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());

            if (!user.id_estatus_actividad || !user.id_tipo_actividad) {
                throw new Error("El estatus y el tipo de actividad son obligatorios.");
            }

            let result;
            if (user.id_actividad) {
                // Editar actividad existente
                result = await supabase
                    .from('cat_actividades')
                    .update({
                        id_estatus_actividad: user.id_estatus_actividad,
                        id_tipo_actividad: user.id_tipo_actividad,
                        nombre_actividad: user.nombre_actividad,
                        descripcion_actividad: user.descripcion_actividad,
                        fecha_actividad: user.fecha_actividad,
                    })
                    .eq('id_actividad', user.id_actividad);

                showNotification({
                    title: 'Actividad actualizada',
                    message: 'La actividad se ha actualizado correctamente.',
                    color: 'green',
                });
            } else {
                // Añadir nueva actividad
                result = await supabase
                    .from('cat_actividades')
                    .insert({
                        id_trato: idTrato,
                        id_estatus_actividad: user.id_estatus_actividad,
                        id_tipo_actividad: user.id_tipo_actividad,
                        nombre_actividad: user.nombre_actividad,
                        descripcion_actividad: user.descripcion_actividad,
                        fecha_actividad: user.fecha_actividad,
                    });

                showNotification({
                    title: 'Actividad agregada',
                    message: 'La actividad ha sido agregada con éxito',
                    color: 'green',
                });
            }

            if (result.error) throw result.error;

            dispatch(HideLoading());
            getData();
            setShowForm(false);
            setFormMode('view'); // Restablece el modo del formulario a 'view'
        } catch (error) {
            console.error('Error al agregar/actualizar actividad:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar actividad.',
                color: 'red',
            });
            dispatch(HideLoading());
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form onSubmit={handleSubmit} className='caja-75p'>
                <Card className='CardTablas'>
                    <div>
                        <div className='FiltroActividades' style={{ display: 'flex', gap: '5px', flexWrap: 'nowrap' }}>
                            <TextInput
                                disabled
                                name="id_actividad"
                                label="ID"
                                className='caja-150'
                                value={user.id_actividad}
                                style={{ flex: '1 1 5%', minWidth: '20px' }} // Ajusta el tamaño mínimo para que se vea bien
                            />
                            <DateTimePicker
                                label="Fecha y hora"
                                placeholder="Selecciona fecha y hora"
                                className='caja-150'
                                value={convertToDate(user.fecha_actividad)}
                                onChange={(date) => setUser({ ...user, fecha_actividad: date ? date.toISOString() : "" })}
                                style={{ flex: '1 1 18%', minWidth: '120px' }} // Ajusta el tamaño mínimo
                            />
                        </div>
                        <div className='FiltroActividades' style={{ display: 'flex', gap: '5px', flexWrap: 'nowrap' }}>

                            <TextInput
                                label="Nombre de la Actividad"
                                name="nombre_actividad"
                                placeholder="Nombre de la Actividad"
                                className='caja-150'
                                value={user.nombre_actividad}
                                onChange={handleChange}
                                style={{ flex: '1 1 23%', minWidth: '150px' }} // Ajusta el tamaño mínimo
                            />
                        </div>
                        <div className='FiltroActividades' style={{ display: 'flex', gap: '5px', flexWrap: 'nowrap' }}>

                            <NativeSelect
                                label="Estatus"
                                name="id_estatus_actividad"
                                placeholder="Seleccione un estatus"
                                className='caja-150'
                                value={user.id_estatus_actividad}
                                onChange={handleChangeSelect}
                                style={{ flex: '1 1 20%', minWidth: '150px' }} // Ajusta el tamaño mínimo
                            >
                                <option value="">Seleccione un estatus</option>
                                {listadoestatusactividad.map((tipo) => (
                                    <option key={tipo.id_estatus_actividad} value={tipo.id_estatus_actividad}>
                                        {tipo.nombre_estatus_actividad}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                label="Tipo"
                                name="id_tipo_actividad"
                                placeholder="Seleccione un tipo"
                                className='caja-150'
                                value={user.id_tipo_actividad}
                                onChange={handleChangeSelect}
                                style={{ flex: '1 1 20%', minWidth: '150px' }} // Ajusta el tamaño mínimo
                            >
                                <option value="">Seleccione un tipo</option>
                                {listadotiposactividades.map((tipo) => (
                                    <option key={tipo.id_tipo_actividad} value={tipo.id_tipo_actividad}>
                                        {tipo.nombre_tipo_actividad}
                                    </option>
                                ))}
                            </NativeSelect>
                        </div>
                    </div>
                    <Textarea
                        label="Descripción"
                        name="descripcion_actividad"
                        placeholder="Descripción"
                        styles={{
                            input: {
                                backgroundColor: '#FCF3C2' // Color amarillo
                            }
                        }}
                        autosize

                        minRows={4}

                        value={user.descripcion_actividad}
                        onChange={handleChange}
                    />
                    <Group justify="center" mt="md">
                        <Button type="submit" className='boton-morado'>
                            Guardar
                        </Button>
                        <Button color="red" onClick={() => setShowForm(false)}>
                            Cancelar
                        </Button>
                    </Group>
                </Card>
            </form>
        </div>
    );
}

export default ActividadesFormResumido;
