import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function EstadosTratosTable({ estadostratos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEstadoTrato, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    
    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del estadotrato si se ha proporcionado un filtro
    const estadostratosFiltrados = filtroEstadoTrato
        ? estadostratos.filter(estadotrato => estadotrato.nombre_estado_trato.toLowerCase().includes(filtroEstadoTrato.toLowerCase()))
        : estadostratos;

    const paginatedTransactions = estadostratosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_estados_tratos')
                .delete()
                .eq('id_estado_trato', id);

            if (error) {
                throw new Error('No se pudo borrar el estado trato');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Estado Trato eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el estado trato',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((estadotrato) => (
        <tr key={estadotrato.id_estado_trato}>
            <td>{estadotrato.id_estado_trato}</td>
            <td>{truncateText(estadotrato.nombre_estado_trato,50)}</td>
            
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estados_tratos &&
                        permisos.catalogos.modulos.cat_estados_tratos.acciones &&
                        permisos.catalogos.modulos.cat_estados_tratos.acciones.modificar_estado_trato && (
                            <i
                                key={`edit-${estadotrato.id_estado_trato}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(estadotrato);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estados_tratos &&
                        permisos.catalogos.modulos.cat_estados_tratos.acciones &&
                        permisos.catalogos.modulos.cat_estados_tratos.acciones.eliminar_estado_trato && (
                            <i
                                key={`delete-${estadotrato.id_estado_trato}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este estado trato?')) {
                                        deleteTransaction(estadotrato.id_estado_trato);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre estadotrato',
    ];

    const csvData = estadostratos.map((estadotrato) => [
        estadotrato.id_estado_trato,
        estadotrato.nombre_estado_trato,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre Estado Trato</th>
                        
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(estadostratos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'estadostratos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default EstadosTratosTable;
