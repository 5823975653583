import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination, Badge } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import moment from 'moment';


function ActividadesTable({ actividades, setSelectedTransaction, setFormMode, setShowForm, getData, filtroActividad, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;

    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del actividad si se ha proporcionado un filtro
    const actividadesFiltradas = filtroActividad
        ? actividades.filter(actividad => actividad.nombre_actividad.toLowerCase().includes(filtroActividad.toLowerCase()))
        : actividades;

    const paginatedTransactions = actividadesFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_actividades')
                .delete()
                .eq('id_actividad', id);

            if (error) {
                throw new Error('No se pudo borrar la actividad');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Actividad eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la actividad',
                color: 'red'
            });
        }
    };

    const duplicateTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Obtener la actividad actual
            const { data, error } = await supabase
                .from('cat_actividades')
                .select('*')
                .eq('id_actividad', id)
                .single();

            if (error || !data) {
                throw new Error('No se pudo obtener la actividad');
            }

            // Crear una nueva actividad duplicada
            delete data.id_actividad; // Eliminar el ID para insertar como nuevo
            const { error: insertError } = await supabase
                .from('cat_actividades')
                .insert([data]);

            if (insertError) {
                throw new Error('No se pudo duplicar la actividad');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Actividad duplicada',
                color: 'green'
            });

            getData(); // Obtener nuevamente los datos después de la duplicación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo duplicar la actividad',
                color: 'red'
            });
        }
    };


    const getRows = paginatedTransactions.map((actividad) => (
        <tr key={actividad.id_actividad}>
            <td>{actividad.id_actividad}</td>
            <td>{actividad.nombre_actividad}</td>
            <td>{truncateText(actividad.descripcion_actividad, 25)}</td>
            <td>{moment(actividad.fecha_actividad).format('DD-MM-YY HH:mm')}</td>
            <td>
                <Badge
                    size="sm"
                    color={actividad.cat_estatus_actividades.nombre_estatus_actividad === 'Completada' ? 'green' : 'red'}
                    style={{ color: 'white' }}
                >
                    {actividad.cat_estatus_actividades.nombre_estatus_actividad}
                </Badge>
            </td>            <td>{actividad.cat_tratos.cat_empresas.nombre_corto_empresa}</td>

            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_actividades &&
                        permisos.catalogos.modulos.cat_actividades.acciones &&
                        permisos.catalogos.modulos.cat_actividades.acciones.modificar_actividad && (
                            <i
                                key={`edit-${actividad.id_actividad}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(actividad);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_actividades &&
                        permisos.catalogos.modulos.cat_actividades.acciones &&
                        permisos.catalogos.modulos.cat_actividades.acciones.modificar_actividad && (
                            <i
                                key={`duplicate-${actividad.id_actividad}`}
                                className='ri-file-copy-line'
                                onClick={() => {
                                    duplicateTransaction(actividad.id_actividad);
                                }}
                            ></i>
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_actividades &&
                        permisos.catalogos.modulos.cat_actividades.acciones &&
                        permisos.catalogos.modulos.cat_actividades.acciones.eliminar_actividad && (
                            <i
                                key={`delete-${actividad.id_actividad}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este actividad?')) {
                                        deleteTransaction(actividad.id_actividad);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre actividad',
    ];

    const csvData = actividades.map((actividad) => [
        actividad.id_actividad,
        actividad.nombre_actividad,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Fecha</th>
                        <th>Estatus</th>
                        <th>Empresa</th>

                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(actividades.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'actividades.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ActividadesTable;