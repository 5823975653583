import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, NativeSelect, MultiSelect, Tabs, Modal, Text, Accordion, rem } from '@mantine/core';
import { IconCoinFilled, IconUserFilled, IconPlus, IconBuildingFactory2 } from '@tabler/icons-react';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import ListadoActividades from '../pages/ListadoActividades2';
import ContactosForm from './ContactosForm';
import EmpresasForm from './EmpresasForm';


function TratosForm({ formMode, setFormMode, setShowForm, transactionData, getData, user, permisos }) {
  const [user2, setUser] = useState({
    nombre_trato: "",
    descripcion_trato: "",
    id_empresa: "",
    id_categoria_trato: "",
    nombre_categoria_trato: "",
    id_estado_trato: "",
    nombre_estado_trato: "",
    id_etapa_trato: "",
    valor_trato: "",
    fecha_trato_ganado: null,
    fecha_trato_perdido: null,
    fecha_trato_abierto: null,
    fecha_trato_declinado: null,
    id_fuente: "",

  });

  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedContactos, setSelectedContactos] = useState([]);

  const [ctratos, setCtratos] = useState([]);
  const [selectedCtrato, setSelectedCtrato] = useState("");

  const [empresas, setEmpresas] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [showContactoForm, setShowContactoForm] = useState(false); // para agregar un contacto nuevo
  const [showEmpresaForm, setShowEmpresaForm] = useState(false); // para agregar un contacto nuevo

  const [estadotratos, setEstadotratos] = useState([]);
  const [selectedEstadoTrato, setSelectedEstadotratos] = useState("");

  const [etapatratos, setEtapatratos] = useState([]);
  const [selectedEtapaTrato, setSelectedEtapatratos] = useState("");

  const [empresaFilter, setEmpresaFilter] = useState("");

  const [fuentes, setFuentes] = useState([]);
  const [selectedFuente, setSelectedFuente] = useState("");

  const [errors, setErrors] = useState({});





  useEffect(() => {
    if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
      setUser({
        nombre_trato: transactionData.nombre_trato || "",
        descripcion_trato: transactionData.descripcion_trato || "",
        id_empresa: transactionData.id_empresa || "",
        id_categoria_trato: transactionData.id_categoria_trato || "",
        id_estado_trato: transactionData.id_estado_trato || "",
        id_etapa_trato: transactionData.id_etapa_trato || "",
        valor_trato: transactionData.valor_trato || "",
        fecha_trato_ganado: transactionData.fecha_trato_ganado || null,
        fecha_trato_perdido: transactionData.fecha_trato_perdido || null,
        fecha_trato_abierto: transactionData.fecha_trato_abierto || null,
        fecha_trato_declinado: transactionData.fecha_trato_declinado || null,
        id_fuente: transactionData.id_fuente || "",

      });
      setSelectedEmpresa(transactionData.id_empresa || "");
      setSelectedContactos(transactionData.contactos.map(contacto => String(contacto.id_contacto)));
      setSelectedCtrato(transactionData.id_categoria_trato || "");
      setSelectedEstadotratos(transactionData.id_estado_trato || "");
      setSelectedEtapatratos(transactionData.id_etapa_trato || "");
      setSelectedFuente(transactionData.id_fuente || "");
    }
  }, [formMode, transactionData]);

  useEffect(() => {
    async function fetchData() {
      const { data: empresas } = await supabase.from('cat_empresas').select('id_empresa, nombre_corto_empresa').order('nombre_corto_empresa', { ascending: true });
      setEmpresas(empresas || []);

      const { data: ctratos } = await supabase.from('cat_categorias_tratos').select('id_categoria_trato, nombre_categoria_trato').order('nombre_categoria_trato', { ascending: true });
      setCtratos(ctratos || []);

      const { data: estadotratos } = await supabase.from('cat_estados_tratos').select('id_estado_trato, nombre_estado_trato').order('nombre_estado_trato', { ascending: true });
      setEstadotratos(estadotratos || []);

      const { data: etapatratos } = await supabase.from('cat_etapas_tratos').select('id_etapa_trato, nombre_etapa_trato').order('nombre_etapa_trato', { ascending: true });
      setEtapatratos(etapatratos || []);

      const { data: contactos } = await supabase.from('cat_contactos').select('id_contacto, nombre_contacto').order('nombre_contacto', { ascending: true });
      setContactos(contactos || []);

      const { data: fuentes } = await supabase.from('cat_fuentes').select('id_fuente, nombre_fuente').order('nombre_fuente', { ascending: true });
      setFuentes(fuentes || []);

    }
    fetchData();
  }, []);

  const fetchContactos = async () => {
    try {
      const { data: contactos } = await supabase
        .from('cat_contactos')
        .select('id_contacto, nombre_contacto')
        .order('nombre_contacto', { ascending: true });

      setContactos(contactos || []);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const fetchEmpresas = async () => {
    try {
      const { data: empresas } = await supabase
        .from('cat_empresas')
        .select('id_empresa, nombre_corto_empresa')
        .order('nombre_corto_empresa', { ascending: true });

      setEmpresas(empresas || []);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    fetchContactos();
    fetchEmpresas();
  }, []);

  useEffect(() => {
    async function fetchFilteredEmpresas() {
      const { data: filteredEmpresas } = await supabase
        .from('cat_empresas')
        .select('id_empresa, nombre_corto_empresa')
        .ilike('nombre_corto_empresa', `%${empresaFilter}%`)
        .order('nombre_corto_empresa', { ascending: true });
      setEmpresas(filteredEmpresas || []);
    }

    if (empresaFilter) {
      fetchFilteredEmpresas();
    } else {
      async function fetchAllEmpresas() {
        const { data: empresas } = await supabase.from('cat_empresas').select('id_empresa, nombre_corto_empresa').order('nombre_corto_empresa', { ascending: true });
        setEmpresas(empresas || []);
      }
      fetchAllEmpresas();
    }
  }, [empresaFilter]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeSelect = (event) => {
    setSelectedEmpresa(event.target.value);
  };

  const handleCtratoChange = (event) => {
    setSelectedCtrato(event.target.value);
  };

  const handleEstadoTratoChange = (event) => {
    setSelectedEstadotratos(event.target.value);
  };

  const handleEtapaChange = (event) => {
    setSelectedEtapatratos(event.target.value);
  };

  const handleChangeMultiSelect = (value) => {
    setSelectedContactos(value);
  };

  const handleFuenteChange = (event) => {
    setSelectedFuente(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar selección de contactos
    if (selectedContactos.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactos: 'Debes seleccionar al menos un contacto.',
      }));
      showNotification({
        title: 'Error',
        message: 'Debe seleccionar al menos un contacto. No se guardarán los cambios ',
        color: 'red',
      });
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactos: '',
      }));
    }

    try {
      let tratoId;
      if (formMode === 'add') {
        const { data, error } = await supabase.from('cat_tratos').insert([{
          nombre_trato: user2.nombre_trato,
          descripcion_trato: user2.descripcion_trato,
          id_empresa: selectedEmpresa,
          id_categoria_trato: selectedCtrato,
          id_estado_trato: selectedEstadoTrato,
          id_etapa_trato: selectedEtapaTrato,
          valor_trato: user2.valor_trato,
          fecha_trato_ganado: user2.fecha_trato_ganado,
          fecha_trato_perdido: user2.fecha_trato_perdido,
          fecha_trato_abierto: user2.fecha_trato_abierto,
          fecha_trato_declinado: user2.fecha_trato_declinado,
          id_fuente: selectedFuente,
        }]).select();

        if (error) throw error;
        tratoId = data[0].id_trato;

        setShowForm(false);

      } else if (formMode === 'edit') {
        await supabase.from('cat_tratos').update({
          nombre_trato: user2.nombre_trato,
          descripcion_trato: user2.descripcion_trato,
          id_empresa: selectedEmpresa,
          id_categoria_trato: selectedCtrato,
          id_estado_trato: selectedEstadoTrato,
          id_etapa_trato: selectedEtapaTrato,
          valor_trato: user2.valor_trato,
          fecha_trato_ganado: user2.fecha_trato_ganado,
          fecha_trato_perdido: user2.fecha_trato_perdido,
          fecha_trato_abierto: user2.fecha_trato_abierto,
          fecha_trato_declinado: user2.fecha_trato_declinado,
          id_fuente: selectedFuente,

        }).eq('id_trato', transactionData.id_trato);

        tratoId = transactionData.id_trato;
      }

      await supabase.from('cat_tratos_contactos').delete().eq('id_trato', tratoId);
      const insertData = selectedContactos.map((contactoId) => ({ id_trato: tratoId, id_contacto: parseInt(contactoId) }));
      await supabase.from('cat_tratos_contactos').insert(insertData);

      showNotification({
        title: formMode === 'add' ? 'Trato agregado' : 'Trato actualizado',
        message: formMode === 'add' ? 'El trato ha sido agregado con éxito' : 'El trato ha sido actualizado con éxito',
        color: 'green',
      });

      //setShowForm(false);
      getData();
    } catch (error) {
      console.error('Error al agregar/actualizar trato:', error.message);
      showNotification({
        title: 'Error',
        message: 'Hubo un error al agregar/actualizar trato.',
        color: 'red',
      });
    }
  };

  const handleEmpresaFilterChange = (event) => {
    setEmpresaFilter(event.target.value);
  };

  return (
    <div style={{ display: 'flex', gap: '20px', backgroundColor: '#F0F0F0' }}>
      {/* Columna izquierda: Formulario de detalles */}
      <div style={{ flex: 1 }}>
        <Tabs defaultValue="detalles">
          <Tabs.Panel className="AccordionPanel" value="detalles" style={{ flex: 1, background: 'white', padding: '10px', margin: '10px' }}>

            <form onSubmit={handleSubmit}>
              <form onSubmit={handleSubmit}>
                <Group>
                  <Stack>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <IconCoinFilled size={28} style={{ color: '#8D5EAD' }} />
                      <Text size="xl" fw={700}>
                        Detalles del Trato
                      </Text>
                    </div>
                    <Group>

                      <TextInput
                        name="nombre_trato"
                        label="Nombre Trato"
                        className='caja-200'
                        required
                        onChange={handleChange}
                        value={user2.nombre_trato}
                        placeholder="Nombre trato"
                      />

                    </Group>
                    <Group>

                      <TextInput
                        name="descripcion_trato"
                        label="Descripción Trato"
                        className='caja-200'
                        required
                        onChange={handleChange}
                        value={user2.descripcion_trato}
                        placeholder="Descripción trato"
                      />
                      <NativeSelect
                        name="id_fuente"
                        label="Fuente"
                        className='caja-200'
                        value={selectedFuente}
                        onChange={handleFuenteChange}
                        placeholder=""
                        required
                      >
                        <option value="">Seleccione Fuente</option>
                        {fuentes.map((ii) => (
                          <option key={ii.id_fuente} value={ii.id_fuente}>
                            {ii.nombre_fuente}
                          </option>
                        ))}
                      </NativeSelect>
                    </Group>
                    <Group>
                      <NativeSelect
                        name="id_estado_trato"
                        label="Estado"
                        className='caja-200'
                        value={selectedEstadoTrato}
                        onChange={handleEstadoTratoChange}
                        placeholder=""
                        required
                      >
                        <option value="">Seleccione Estado</option>
                        {estadotratos.map((ii) => (
                          <option key={ii.id_estado_trato} value={ii.id_estado_trato}>
                            {ii.nombre_estado_trato}
                          </option>
                        ))}
                      </NativeSelect>
                      <TextInput
                        name="valor_trato"
                        label="Valor Trato"
                        className='caja-200'
                        required
                        onChange={handleChange}
                        value={user2.valor_trato}
                        placeholder="Valor MXN"
                        type="number"
                      />

                    </Group>
                    <Group>
                      <NativeSelect
                        name="id_categoria_trato"
                        label="Categoría"
                        className='caja-200'
                        value={selectedCtrato}
                        onChange={handleCtratoChange}
                        placeholder=""
                        required
                      >
                        <option value="">Seleccione Categoría</option>
                        {ctratos.map((i) => (
                          <option key={i.id_categoria_trato} value={i.id_categoria_trato}>
                            {i.nombre_categoria_trato}
                          </option>
                        ))}
                      </NativeSelect>

                      <NativeSelect
                        name="id_etapa_trato"
                        label="Etapa"
                        className='caja-200'
                        value={selectedEtapaTrato}
                        onChange={handleEtapaChange}
                        placeholder=""
                        required
                      >
                        <option value="">Seleccione Etapa</option>
                        {etapatratos.map((ii) => (
                          <option key={ii.id_etapa_trato} value={ii.id_etapa_trato}>
                            {ii.nombre_etapa_trato}
                          </option>
                        ))}
                      </NativeSelect>
                    </Group>

                    <div className="responsive-form">
                      <div className="responsive-form-input">
                        <TextInput
                          label="Filtro Empresa"
                          value={empresaFilter}
                          onChange={handleEmpresaFilterChange}
                        />
                      </div>
                      <div className="responsive-form-select">
                        <NativeSelect
                          name="id_empresa"
                          label="Empresa"
                          value={selectedEmpresa}
                          onChange={handleChangeSelect}
                          placeholder=""
                          required
                        >
                          <option value="">Seleccione una empresa</option>
                          {empresas.map((empresa) => (
                            <option key={empresa.id_empresa} value={empresa.id_empresa}>
                              {empresa.nombre_corto_empresa}
                            </option>
                          ))}
                        </NativeSelect>
                      </div>
                      <Button
                        className="responsive-form-button"
                        onClick={() => setShowEmpresaForm(true)}
                        color='#8D5EAD'
                        style={{
                          padding: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '4px',
                          height: '36px',
                          flexShrink: 0,  // Evita que el botón se encoja

                        }}
                      >
                        <IconPlus size={18} />
                        <IconBuildingFactory2 size={18} />
                      </Button>
                    </div>

                    <div className="responsive-form">
                      <div className="responsive-form-select">
                        <MultiSelect
                          label="Selecciona contactos"
                          placeholder="Seleccione uno o más contactos"
                          data={contactos.map(contacto => ({
                            value: contacto.id_contacto.toString(),
                            label: contacto.nombre_contacto
                          }))}
                          value={selectedContactos}
                          onChange={handleChangeMultiSelect}
                          searchable
                          required
                        />
                        {errors.contactos && <Text color="red">{errors.contactos}</Text>}

                      </div>
                      <Button
                        className="responsive-form-button"
                        onClick={() => setShowContactoForm(true)}
                        color='#8D5EAD'
                        style={{
                          padding: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '4px',
                          height: '36px',
                          flexShrink: 0,  // Evita que el botón se encoja
                        }}
                      >
                        <IconPlus size={18} />
                        <IconUserFilled size={18} />
                      </Button>
                    </div>

                    <Group>

                      <TextInput
                        name="fecha_trato_abierto"
                        label="Fecha trato abierto"
                        className='caja-200'
                        required
                        onChange={handleChange}
                        value={user2.fecha_trato_abierto}
                        placeholder="Fecha"
                        type="date"
                      />
                      <TextInput
                        name="fecha_trato_ganado"
                        label="Fecha ganado"
                        className='caja-200'

                        onChange={handleChange}
                        value={user2.fecha_trato_ganado}
                        placeholder="Fecha"
                        type="date"
                      />
                    </Group>
                    <Group>
                      <TextInput
                        name="fecha_trato_perdido"
                        label="Fecha trato perdido"
                        className='caja-200'

                        onChange={handleChange}
                        value={user2.fecha_trato_perdido}
                        placeholder="Fecha"
                        type="date"
                      />
                      <TextInput
                        name="fecha_trato_declinado"
                        label="Fecha trato declinado"
                        className='caja-200'

                        onChange={handleChange}
                        value={user2.fecha_trato_declinado}
                        placeholder="Fecha"
                        type="date"
                      />

                    </Group>

                  </Stack>
                </Group>

              </form>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button mt={15} className='boton-verde' type="submit">
                  {formMode === 'add' ? "+ Agregar Trato" : "Editar Trato"}
                </Button>
              </div>
            </form>
          </Tabs.Panel>
        </Tabs>
        <Modal
          opened={showContactoForm}
          onClose={() => setShowContactoForm(false)}
          title="Agregar Nuevo Contacto"
          size={'auto'}
        >
          <ContactosForm
            formMode="add"
            setShowForm={setShowContactoForm}
            getData={fetchContactos}
          />
        </Modal>
        <Modal
          opened={showEmpresaForm}
          onClose={() => setShowEmpresaForm(false)}
          title="Agregar Nueva Empresa"
          size={'auto'}
        >
          <EmpresasForm
            formMode="add"
            setShowForm={setShowEmpresaForm}
            getData={fetchEmpresas}
          />
        </Modal>
      </div>

      {/* Columna derecha: ListadoActividades */}
      <div style={{ flex: 2, padding: '10px' }}>
        {formMode === 'edit' ? (
          <ListadoActividades
            user={user}
            permisos={permisos}
            idTrato={transactionData.id_trato}
          />

        ) : (
          <p>Guarde el trato primero para poder agregar actividades.</p>
        )}
      </div>
      

    </div>
  );
}

export default TratosForm;
