import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import ContactosTable from '../components/ContactosTable';
import ContactosForm from '../components/ContactosForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";




function CatalogoContactos({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [contactos, setContactos] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroContacto, setFiltroContacto] = useState('');
   


    useEffect(() => {
        //body
        try {
            const getContactos = async () => {
                dispatch(ShowLoading());
                const allContactos = await fetchContactos()
                setContactos(allContactos) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getContactos()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchContactos = async () => {
        try {
            let { data: contactos, error } = await supabase
                .from('cat_contactos')
                .select(`
                id_contacto,
                nombre_contacto,
                celular_contacto,
                correo_contacto,
                puesto_contacto,
                notas_contacto,
                id_empresa,
                cat_empresas (id_empresa, nombre_corto_empresa)
                
                `)
                .order('id_contacto', { ascending: true })
            //console.log("contactos obtenidos....:", contactos); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return contactos;
        } catch (error) {
            //console.error('Error al obtener contactos:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: contactosFiltrados, error } = await supabase
                .from('cat_contactos')
                .select(`
                id_contacto,
                nombre_contacto,
                celular_contacto,
                correo_contacto,
                puesto_contacto,
                notas_contacto,
                id_empresa,
                cat_empresas (id_empresa, nombre_corto_empresa)
                
                `)
                .ilike('nombre_contacto', `%${filtroContacto.toLowerCase()}%`)
                .order('id_contacto', { ascending: true });

            if (error) {
                throw error;
            }
            setContactos(contactosFiltrados);
        } catch (error) {
            //console.error('Error al filtrar contactos:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allContactos = await fetchContactos();
            setContactos(allContactos);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    
    const canAddContacto = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_contactos &&
        permisos.catalogos.modulos.cat_contactos.acciones &&
        permisos.catalogos.modulos.cat_contactos.acciones.registrar_contacto === true;
    

    return (
        <div>
            
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar contacto"
                                value={filtroContacto}
                                onChange={(event) => setFiltroContacto(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddContacto && (
                                <Button
                                    className='boton-gris'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Contacto
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Contacto" : "Editar Contacto"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ContactosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar aseguradora seleccionada como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>

                    <ContactosTable
                        contactos={contactos}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoContactos;