import React, { useState, useEffect } from 'react';
import { Select, Button } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {supabase} from '../supabaseClient';

const BarChartTratosGanadosFiltro = ({ onFilter }) => {
    const [categorias, setCategorias] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
  
    useEffect(() => {
      const fetchCategorias = async () => {
        let { data: cat_categorias_tratos, error } = await supabase
          .from('cat_categorias_tratos')
          .select('id_categoria_trato, nombre_categoria_trato');
        if (error) console.error('Error fetching categories:', error);
        else setCategorias(cat_categorias_tratos);
      };
      fetchCategorias();
    }, []);
  
    const handleFilter = () => {
      if (!categoriaSeleccionada || !fechaInicio || !fechaFin) {
        console.error('Todos los campos son obligatorios.');
        return;
      }
      onFilter({ categoriaSeleccionada, fechaInicio, fechaFin });
    };
  
    return (
      <div>
        <Select
          label="Categoria"
          placeholder="Seleccione una categoria"
          data={categorias.map(cat => ({
            value: String(cat.id_categoria_trato), // Convertimos el id a string
            label: cat.nombre_categoria_trato
          }))}
          value={categoriaSeleccionada}
          onChange={setCategoriaSeleccionada}
        />
        <DatePickerInput 
          label="Fecha Inicial"
          placeholder="Seleccione fecha inicial"
          value={fechaInicio}
          onChange={setFechaInicio}
          withTime={false} // Para asegurarnos de que solo seleccionas día, mes y año
        />
        <DatePickerInput 
          label="Fecha Final"
          placeholder="Seleccione fecha final"
          value={fechaFin}
          onChange={setFechaFin}
          withTime={false} // Para asegurarnos de que solo seleccionas día, mes y año
        />
        <Button onClick={handleFilter}>Filtrar</Button>
      </div>
    );
  };
  
  export default BarChartTratosGanadosFiltro;
