import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';



function FuentesTable({ fuentes, setSelectedTransaction, setFormMode, setShowForm, getData, filtroFuente, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const fuentesFiltradas = filtroFuente
        ? fuentes.filter(fuente => fuente.nombre_fuente.toLowerCase().includes(filtroFuente.toLowerCase()))
        : fuentes;

    const paginatedTransactions = fuentesFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_fuentes')
                .delete()
                .eq('id_fuente', id);

            if (error) {
                throw new Error('No se pudo borrar la Fuente');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Fuente borrada',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Fuente',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((fuente) => (
        <tr key={fuente.id_fuente}>
            <td>{fuente.id_fuente}</td>
            <td>{fuente.nombre_fuente}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_fuentes &&
                        permisos.catalogos.modulos.cat_fuentes.acciones &&
                        permisos.catalogos.modulos.cat_fuentes.acciones.modificar_fuente && (
                            <i
                                key={`edit-${fuente.id_fuente}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(fuente);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Fuente seleccionada:", fuente);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_fuentes &&
                        permisos.catalogos.modulos.cat_fuentes.acciones &&
                        permisos.catalogos.modulos.cat_fuentes.acciones.eliminar_fuente && (

                            <i
                                key={`delete-${fuente.id_fuente}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar esta Fuente?')) {
                                        deleteTransaction(fuente.id_fuente);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre fuente',
    ];

    const csvData = fuentes.map((fuente) => [
        fuente.id_fuente,
        fuente.nombre_fuente,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre fuente</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(fuentes.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'fuentes.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default FuentesTable;