//import '@mantine/dates/styles.css';
import React, { useEffect } from 'react';
import { Group, Button, Autocomplete, Select } from '@mantine/core';
import { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import { rem } from '@mantine/core';






function TratosFiltros({ setFilters, filters, getData, onEnterPress }) {
    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

    const [tratos, setTratos] = useState([]);
    const [fuentes, setFuentes] = useState([]);
    const [estados, setEstados] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [contactos, setContactos] = useState([]);


    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnterPress(); // Llama a la función getData pasada como prop
        }
    };


    useEffect(() => {
        const fetchTratos = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_tratos')
                    .select('nombre_trato');

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((i) => ({
                    label: i.nombre_trato,
                    value: i.nombre_trato
                }));

                setTratos(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchFuentes = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_fuentes')
                    .select('nombre_fuente');

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_fuente,
                    value: ii.nombre_fuente
                }));

                setFuentes(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchEstados = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_estados_tratos')
                    .select('nombre_estado_trato');

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_estado_trato,
                    value: ii.nombre_estado_trato
                }));

                setEstados(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchCategorias = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_categorias_tratos')
                    .select('nombre_categoria_trato')
                    .order('nombre_categoria_trato', { ascending: true })

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_categoria_trato,
                    value: ii.nombre_categoria_trato
                }));

                setCategorias(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchEtapas = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_etapas_tratos')
                    .select('nombre_etapa_trato')
                    .order('id_etapa_trato', { ascending: true })

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_etapa_trato,
                    value: ii.nombre_etapa_trato
                }));

                setEtapas(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchEmpresas = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_empresas')
                    .select('nombre_corto_empresa')
                    .order('nombre_corto_empresa', { ascending: true })

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_corto_empresa,
                    value: ii.nombre_corto_empresa
                }));

                setEmpresas(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchContactos = async () => {
            try {
                // Consulta para select 
                const { data: Data, error: estatusError } = await supabase
                    .from('cat_contactos')
                    .select('nombre_contacto')
                    .order('nombre_contacto', { ascending: true })

                if (estatusError) {
                    throw estatusError;
                }

                const options = Data.map((ii) => ({
                    label: ii.nombre_contacto,
                    value: ii.nombre_contacto
                }));

                setContactos(options);
            } catch (error) {
                //console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };



        fetchTratos();
        fetchFuentes();
        fetchEstados();
        fetchCategorias();
        fetchEtapas();
        fetchEmpresas();
        fetchContactos();
    }, []);

    return (
        <div>
            <div onKeyDown={handleKeyDown} className='FiltroActividades'>
                <Autocomplete
                    label="Categoría"
                    placeholder="Categoría"
                    data={categorias}
                    value={filters.categorias}
                    onChange={(value) => setFilters({ ...filters, nombre_categoria_trato: value })}
                    name="nombre_categoria_trato"
                    className='caja-150'
                />
                <Autocomplete
                    label="Fuente"
                    placeholder="Fuente"
                    data={fuentes}
                    value={filters.fuentes}
                    onChange={(value) => setFilters({ ...filters, nombre_fuente: value })}
                    name="nombre_fuente"
                    className='caja-150'
                />
                <Select
                    label="Estado trato"
                    placeholder="Estado"
                    data={estados}
                    value={filters.estados}
                    onChange={(value) => setFilters({ ...filters, nombre_estado_trato: value })}
                    name="nombre_estado_trato"
                    className='caja-150'
                />
                
                <Autocomplete
                    label="Trato"
                    placeholder="Trato"
                    data={tratos}
                    value={filters.tratos}
                    onChange={(value) => setFilters({ ...filters, nombre_trato: value })}
                    name="nombre_trato"
                    className="caja-150"

                />

                <Autocomplete
                    label="Etapa"
                    placeholder="Categoría"
                    data={etapas}
                    value={filters.etapas}
                    onChange={(value) => setFilters({ ...filters, nombre_etapa_trato: value })}
                    name="nombre_etapa_trato"
                    className='caja-150'
                />
                <Autocomplete
                    label="Empresa"
                    placeholder="Empresa"
                    data={empresas}
                    value={filters.empresas}
                    onChange={(value) => setFilters({ ...filters, nombre_corto_empresa: value })}
                    name="nombre_corto_empresa"
                    className='caja-150'
                />
                <Autocomplete
                    label="Contacto"
                    placeholder="Contacto"
                    data={contactos}
                    value={filters.contactos}
                    onChange={(value) => setFilters({ ...filters, nombre_contacto: value })}
                    name="nombre_contacto"
                    className='caja-150'
                />

            </div>
        </div>
    );
}

export default TratosFiltros;