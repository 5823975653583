import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination, Modal, NumberFormatter, Card, Badge } from '@mantine/core';
import { useForm } from '@mantine/form';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
//import { Pencil, Trash, Calendar } from 'tabler-icons-react';
import ActividadesForm from './ActividadesForm'; // Importa el formulario de actividades
import ActividadesTable from './ActividadesTable'; // Importa la tabla de actividades

function TratosTable({ tratos, setSelectedTransaction, setFormMode, setShowForm, getData, fitroTratos, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTrato, setSelectedTrato] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const itemsPerPage = 100;

    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del trato si se ha proporcionado un filtro
    const tratosFiltrados = fitroTratos
        ? tratos.filter(trato => trato.nombre_trato.toLowerCase().includes(fitroTratos.toLowerCase()))
        : tratos;

    const paginatedTransactions = tratosFiltrados.slice(startIndex, endIndex);

    const estadoTratoColor = (nombre_estado_trato) => {
        switch (nombre_estado_trato) {
            case 'Abierto':
                return 'blue';
            case 'Ganado':
                return 'green';
            case 'Declinó':
                return 'gray';
            case 'Perdido':
                return 'red';
            default:
                return 'gray'; // Default color if no state matches
        }
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_tratos')
                .delete()
                .eq('id_trato', id);

            if (error) {
                throw new Error('No se pudo borrar el trato');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Trato eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el trato',
                color: 'red'
            });
        }
    };

    const handleAddActivity = (trato) => {
        setSelectedTrato(trato);
        setModalOpen(true);
    };

    const getRows = paginatedTransactions.map((trato) => (
        <tr key={trato.id_trato}>
            <td>{trato.id_trato}</td>
            <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                <div><strong>{truncateText(trato.nombre_trato, 45)}</strong></div>
                <div style={{ fontSize: '1em', color: '#555' }}>{truncateText(trato.descripcion_trato, 45)}</div>
                <div><strong>{truncateText(trato.cat_empresas.nombre_corto_empresa, 45)}</strong></div>
            </td>
            <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                <div><strong>Categoría: </strong>{trato.cat_categorias_tratos?.nombre_categoria_trato || ""}</div>

                <div><strong>Fuente: </strong> {trato.cat_fuentes?.nombre_fuente || ""}</div>
            </td>
            <td>
                <Badge color={estadoTratoColor(trato.cat_estados_tratos?.nombre_estado_trato)}>
                    {trato.cat_estados_tratos?.nombre_estado_trato || ""}
                </Badge>
            </td>
            <td>{trato.cat_etapas_tratos?.nombre_etapa_trato || ""}</td>
            <td><NumberFormatter thousandSeparator="," decimalSeparator="." prefix="$ " value={trato.valor_trato} /> </td>

            <td>
                <Group justify="center">
                    {permisos?.catalogos?.modulos?.cat_tratos?.acciones?.modificar_trato && (
                        <i
                            key={`edit-${trato.id_trato}`}
                            className='ri-pencil-line'
                            onClick={() => {
                                setSelectedTransaction(trato);
                                setFormMode("edit");
                                setShowForm(true);
                            }}
                        />
                    )}
                    <i
                        key={`add-activity-${trato.id_trato}`}
                        className='ri-list-check'
                        onClick={() => handleAddActivity(trato)}
                    />
                    {permisos?.catalogos?.modulos?.cat_tratos?.acciones?.eliminar_trato && (
                        <i
                            key={`delete-${trato.id_trato}`}
                            className='ri-delete-bin-line'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de eliminar este trato?')) {
                                    deleteTransaction(trato.id_trato);
                                }
                            }}
                        />
                    )}
                </Group>
            </td>
        </tr>
    ));



    const csvHeaders = [
        'ID',
        'Nombre trato',
    ];

    const csvData = tratos.map((trato) => [
        trato.id_trato,
        trato.nombre_trato,
        trato.cat_empresas.nombre_corto_empresa

    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Trato y Descripción</th>
                        <th>Categoría</th>
                        <th>Estado</th>
                        <th>Etapa</th>
                        <th>Valor</th>


                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>

            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(tratos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'tratos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
            <Modal fullScreen opened={isModalOpen} onClose={() => setModalOpen(false)} title="Agregar Actividades">
                {selectedTrato && (
                    <div style={{ display: 'flex' }}>
                        <Card
                            shadow="sm"
                            style={{ margin: '10px', width: '25%' }}

                        >
                            <Card.Section>
                                <ActividadesForm
                                    tratoId={selectedTrato.id_trato}
                                    nombreTrato={selectedTrato.nombre_trato}
                                    descripcionTrato={selectedTrato.descripcion_trato}
                                    contactos={selectedTrato.contactos} // Pasa los contactos asociados al trato
                                />
                            </Card.Section>
                        </Card>
                        <Card
                            shadow="sm"
                            style={{ margin: '10px', width: '75%' }}

                        >
                            <Card.Section className='tabla-actividades'>
                                <ActividadesTable tratoId={selectedTrato.id_trato} />
                            </Card.Section>
                        </Card>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default TratosTable;
