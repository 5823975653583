import React, { useState } from 'react';
import { Card, Text, NumberFormatter, Group, Badge } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // Aseguramos 4 columnas en pantallas grandes
    gap: '20px',
    padding: '20px',
    [theme.fn.smallerThan('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', // Ajustamos a 300px en pantallas pequeñas
    },
  },
  card: {
    marginBottom: theme.spacing.sm,
  },
}));

const columns = [
  { id: 1, title: 'Hold' },
  { id: 2, title: 'Contactado' },
  { id: 3, title: 'Demostración programada' },
  { id: 4, title: 'Propuesta enviada' },
];

const TratosKanban = ({ tratos, setSelectedTransaction, setFormMode, setShowForm, getData, fitroTratos, user, permisos }) => {
  const { classes } = useStyles();
  const [selectedTrato, setSelectedTrato] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteTransaction = async (id) => {
    try {
      dispatch(ShowLoading());

      const { error } = await supabase
        .from('cat_tratos')
        .delete()
        .eq('id_trato', id);

      if (error) {
        throw new Error('No se pudo borrar el trato');
      }

      dispatch(HideLoading());
      showNotification({
        title: 'Trato eliminado',
        color: 'green'
      });

      getData();
    } catch (error) {
      dispatch(HideLoading());
      showNotification({
        title: 'Error, no se pudo borrar el trato',
        color: 'red'
      });
    }
  };

  const handleAddActivity = (trato) => {
    setSelectedTrato(trato);
    setModalOpen(true);
  };

  const getTratosByEtapa = (etapaId) => {
    return tratos.filter(trato => trato.id_etapa_trato === etapaId);
  };

  const estadoTratoColor = (nombre_estado_trato) => {
    switch (nombre_estado_trato) {
      case 'Abierto':
        return 'blue';
      case 'Ganado':
        return 'green';
      case 'Declinó':
        return 'gray';
      case 'Perdido':
        return 'red';
      default:
        return 'gray';
    }
  };

  const calculateColumnData = (etapaId) => {
    const tratosByEtapa = getTratosByEtapa(etapaId);
    const count = tratosByEtapa.length;
    const totalValue = tratosByEtapa.reduce((sum, trato) => sum + (trato.valor_trato || 0), 0);
    return { count, totalValue };
  };

  return (
    <div style={{ background: 'white' }} className={classes.gridContainer}>
      {columns.map(column => {
        const { count, totalValue } = calculateColumnData(column.id);
        return (
          <Card key={column.id} bg={'#F0F0F0'} withBorder p="lg" shadow='sm' className={classes.card}>
            <Text fw={700}>{column.title}</Text>
            <Text size="sm">{`Total Tratos: ${count}`}</Text>
            <Text size="sm">{`Valor Total: $${totalValue.toLocaleString()}`}</Text>
            {getTratosByEtapa(column.id).map(trato => (
              <Card key={trato.id_trato} bg={'white'} withBorder shadow="sm" p="sm" mt="sm" className={classes.card}>
                <Badge style={{ marginBottom: '5px' }} color={estadoTratoColor(trato.cat_estados_tratos?.nombre_estado_trato)}>
                  {trato.cat_estados_tratos?.nombre_estado_trato || ""}
                </Badge>
                <Text size="sm">{trato.nombre_trato}</Text>
                <Text size="xs" color="dimmed">{trato.cat_empresas.nombre_corto_empresa}</Text>
                <Text size="sm"><NumberFormatter thousandSeparator="," decimalSeparator="." prefix="$ " value={trato.valor_trato} /> </Text>
                <Group justify="center">
                  {permisos?.catalogos?.modulos?.cat_tratos?.acciones?.modificar_trato && (
                    <i
                      key={`edit-${trato.id_trato}`}
                      className='ri-pencil-line'
                      onClick={() => {
                        setSelectedTransaction(trato);
                        setFormMode("edit");
                        setShowForm(true);
                      }}
                    />
                  )}
                  <i
                    key={`add-activity-${trato.id_trato}`}
                    className='ri-list-check'
                    onClick={() => handleAddActivity(trato)}
                  />
                  {permisos?.catalogos?.modulos?.cat_tratos?.acciones?.eliminar_trato && (
                    <i
                      key={`delete-${trato.id_trato}`}
                      className='ri-delete-bin-line'
                      onClick={() => {
                        if (window.confirm('¿Estás seguro de eliminar este trato?')) {
                          deleteTransaction(trato.id_trato);
                        }
                      }}
                    />
                  )}
                </Group>
              </Card>
            ))}
          </Card>
        );
      })}
    </div>
  );
};

export default TratosKanban;
