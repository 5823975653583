import React, { useState, useEffect } from 'react';
import { TextInput, Button, Group, Select, Autocomplete, Flex } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import { rem } from '@mantine/core';

function ActividadesFiltro({ setFilters, filters, onEnterPress }) {
    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);
    const [nombreactividad, setNombreactividad] = useState('');
    const [descripcionactividad, setDescripcionactividad] = useState('');
    const [estatusactividades, setEstatusactividades] = useState([]);
    const [tratos, setTratos] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [estados, setEstados] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnterPress(); // Llama a la función getData pasada como prop
        }
    };

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const { data: empresasData, error: estatusError } = await supabase
                    .from('cat_empresas')
                    .select('nombre_corto_empresa');

                if (estatusError) {
                    throw estatusError;
                }

                const empresasOptions = empresasData.map((empresa) => ({
                    label: empresa.nombre_corto_empresa,
                    value: empresa.nombre_corto_empresa
                }));

                setEmpresas(empresasOptions);
            } catch (error) {
                console.error('Error al obtener empresas:', error.message);
            }
        };

        const fetchTratosData = async () => {
            try {
                const { data: tratosData, error: tratosError } = await supabase
                    .from('cat_tratos')
                    .select('nombre_trato')
                    .order('nombre_trato', { ascending: true });

                if (tratosError) {
                    throw tratosError;
                }

                const tratosOptions = tratosData.map((tratos) => ({
                    label: tratos.nombre_trato,
                    value: tratos.nombre_trato
                }));

                setTratos(tratosOptions);
            } catch (error) {
                console.error('Error al obtener los tratos:', error.message);
            }
        };

        const fetchEstatusActividades = async () => {
            try {
                const { data: estatusactividadesData, error: estatusactividadesError } = await supabase
                    .from('cat_estatus_actividades')
                    .select('nombre_estatus_actividad')
                    .order('nombre_estatus_actividad', { ascending: true });

                if (estatusactividadesError) {
                    throw estatusactividadesError;
                }

                const estatusactividadesOptions = estatusactividadesData.map((estactiv) => ({
                    label: estactiv.nombre_estatus_actividad,
                    value: estactiv.nombre_estatus_actividad
                }));

                setEstatusactividades(estatusactividadesOptions);
            } catch (error) {
                console.error('Error al obtener los estatus:', error.message);
            }
        };

        const fetchCategorias = async () => {
            try {
                const { data: categoriasData, error: categoriasError } = await supabase
                    .from('cat_categorias_tratos')
                    .select('nombre_categoria_trato')
                    .order('nombre_categoria_trato', { ascending: true });

                if (categoriasError) {
                    throw categoriasError;
                }

                const categoriasOptions = categoriasData.map((categorias) => ({
                    label: categorias.nombre_categoria_trato,
                    value: categorias.nombre_categoria_trato
                }));

                setCategorias(categoriasOptions);
            } catch (error) {
                //console.error('Error al obtener las categorias:', error.message);
            }
        };

        const fetchEstados = async () => {
            try {
                const { data: estadosData, error: estadosError } = await supabase
                    .from('cat_estados_tratos')
                    .select('nombre_estado_trato')
                    .order('nombre_estado_trato', { ascending: true });

                if (estadosError) {
                    throw estadosError;
                }

                const estadosOptions = estadosData.map((estados) => ({
                    label: estados.nombre_estado_trato,
                    value: estados.nombre_estado_trato
                }));

                setEstados(estadosOptions);
            } catch (error) {
                //console.error('Error al obtener las categorias:', error.message);
            }
        };

        const fetchEtapas = async () => {
            try {
                const { data: etapasData, error: etapasError } = await supabase
                    .from('cat_etapas_tratos')
                    .select('nombre_etapa_trato')
                    .order('nombre_etapa_trato', { ascending: true });

                if (etapasError) {
                    throw etapasError;
                }

                const etapasOptions = etapasData.map((etapas) => ({
                    label: etapas.nombre_etapa_trato,
                    value: etapas.nombre_etapa_trato
                }));

                setEtapas(etapasOptions);
            } catch (error) {
                //console.error('Error al obtener las categorias:', error.message);
            }
        };



        fetchEmpresas();
        fetchTratosData();
        fetchEstatusActividades();
        fetchCategorias();
        fetchEstados();
        fetchEtapas();
    }, []);

    const handleDateChange = (field, date) => {
        setFilters({ ...filters, [field]: date });
    };

    return (
        <div>

            <div onKeyDown={handleKeyDown} className='FiltroActividades'>
                <Select
                    label="Categoría Trato"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={categorias}
                    value={filters.categorias}
                    onChange={(value) => setFilters({ ...filters, nombre_categoria_trato: value })}
                    name="nombre_categoria_trato"
                />

                <Select
                    label="Estado Trato"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={estados}
                    value={filters.estados}
                    onChange={(value) => setFilters({ ...filters, nombre_estado_trato: value })}
                    name="nombre_estado_trato"
                />
                <Select
                    label="Etapas Trato"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={etapas}
                    value={filters.etapas}
                    onChange={(value) => setFilters({ ...filters, nombre_etapa_trato: value })}
                    name="nombre_etapa_trato"
                />
                <DateTimePicker
                    label="F. inicial"
                    className='caja-150'
                    value={filters.fechaInicial}
                    onChange={(date) => handleDateChange('fechaInicial', date)}
                />
                <DateTimePicker
                    label="F. final"
                    className='caja-150'
                    value={filters.fechaFinal}
                    onChange={(date) => handleDateChange('fechaFinal', date)}
                />
            </div>
            <div onKeyDown={handleKeyDown} className='FiltroActividades'>

                <TextInput
                    label="Nombre de actividad"
                    className='caja-150'

                    placeholder="N. actividad"
                    value={filters.nombre_actividad}
                    onChange={(event) => setFilters({ ...filters, nombre_actividad: event.currentTarget.value })}
                />
                <TextInput
                    label="Descripción"
                    className='caja-150'

                    placeholder="D. de actividad"
                    value={filters.descripcion_actividad}
                    onChange={(event) => setFilters({ ...filters, descripcion_actividad: event.currentTarget.value })}
                />
                <Select
                    label="Estatus actividad"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={estatusactividades}
                    value={filters.estatusactividad}
                    onChange={(value) => setFilters({ ...filters, nombre_estatus_actividad: value })}
                    name="estatus_actividad"
                />
                <Autocomplete
                    label="Trato"
                    placeholder="Trato"
                    className='caja-150'
                    data={tratos}
                    value={filters.nombre_trato}
                    onChange={(value) => setFilters({ ...filters, nombre_trato: value })}
                    name="nombre_trato"
                />
                <Autocomplete
                    label="Empresa"
                    placeholder="Empresa"
                    className='caja-150'
                    data={empresas}
                    value={filters.nombre_corto_empresa}
                    onChange={(value) => setFilters({ ...filters, nombre_corto_empresa: value })}
                    name="empresa"
                />



            </div>
        </div>

    );
}

export default ActividadesFiltro;
