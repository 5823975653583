import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import ActividadesTable from '../components/ActividadesTable2';
import ActividadesTable3 from '../components/ActividadesTable3';
import ActividadesForm from '../components/ActividadesForm2';
import { Group, Button, Modal, Card, Accordion, } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import ActividadesCalendario from '../components/ActividadesCalendario';
import ActividadesFormResumido from '../components/ActividadesFormResumido';

function ListadoActividades({ user, permisos, idTrato }) {
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [actividades, setActividades] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});

    const dispatch = useDispatch();
    const [view, setView] = useState("cards");

    // para popular el filtro empresa y trato cuando abro desde modal de tratos


    const fetchActividades = async () => {
        try {
            let supabaseQuery = supabase
                .from('cat_actividades')
                .select(`
                    id_actividad,
                    nombre_actividad,
                    descripcion_actividad,
                    fecha_actividad,
                    id_tipo_actividad,
                    id_trato,
                    cat_tratos!inner(
                        id_trato, 
                        nombre_trato,
                        descripcion_trato,
                        id_categoria_trato,
                        cat_categorias_tratos!inner(
                            id_categoria_trato,
                            nombre_categoria_trato
                            ),
                        id_estado_trato,
                        cat_estados_tratos!inner(
                            id_estado_trato,
                            nombre_estado_trato
                            ),
                        id_etapa_trato,
                        cat_etapas_tratos!inner(
                            id_etapa_trato,
                            nombre_etapa_trato
                            ),
                        id_empresa,
                        cat_empresas!inner(
                            id_empresa,
                            nombre_corto_empresa
                        )
                    ),
                    id_estatus_actividad,
                    cat_estatus_actividades!inner(
                        id_estatus_actividad, 
                        nombre_estatus_actividad
                    ),
                    id_tipo_actividad,
                    cat_tipos_actividades!inner(
                        id_tipo_actividad,
                        nombre_tipo_actividad
                    )

                `)
                .eq('id_trato', idTrato)  // Añade esta línea para filtrar por id_trato
                .order('fecha_actividad', { ascending: false });




            const { data: actividades, error } = await supabaseQuery;
            //console.log("Actividades...", actividades);

            if (error) {
                throw error;
            }

            return actividades;
        } catch (error) {
            console.error('Error al obtener actividades:', error.message);
            throw error;
        }
    };


    const getData = async () => {
        try {
            if (!idTrato) {
                setActividades([]);
                return;
            }
            dispatch(ShowLoading());
            const allActividades = await fetchActividades();
            setActividades(allActividades);
            dispatch(HideLoading());
        } catch (error) {
            console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red",
            });
            dispatch(HideLoading());
        }
    };

    useEffect(() => {
        getData();
    }, [idTrato]);

    const canAddActividad = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_actividades &&
        permisos.catalogos.modulos.cat_actividades.acciones &&
        permisos.catalogos.modulos.cat_actividades.acciones.registrar_actividad === true;
    // Depuración: Verifica los permisos recibidos
    //console.log('Permisos:', permisos);
    // Depuración: Verifica si canAddActividad es verdadero
    //console.log('Puede agregar actividad:', canAddActividad);

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">

                    <Accordion.Panel className="AccordionPanel">

                        <Group mt={'20px'}>


                            <Button.Group>
                                <Button
                                    variant={view === 'cards' ? "filled" : "outline"}
                                    onClick={() => setView("cards")}
                                >
                                    Tarjetas
                                </Button>
                                <Button
                                    variant={view === 'table' ? "filled" : "outline"}
                                    onClick={() => setView("table")}
                                >
                                    Tabla
                                </Button>
                                <Button
                                    variant={view === 'calendar' ? "filled" : "outline"}
                                    onClick={() => setView("calendar")}
                                >
                                    Calendario
                                </Button>
                            </Button.Group>



                            {canAddActividad && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                        setSelectedTransaction({}); // Limpia la transacción seleccionada

                                    }}>
                                    + Agregar Actividad...
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    //fullScreen
                    title={formMode === "add" ? "Agregar Actividad" : "Editar Actividad"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ActividadesFormResumido
                        idTrato={idTrato}
                        idActividad={selectedTransaction?.id_actividad} // Asegúrate de pasar el idActividad correcto aquí
                        getData={getData}
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        transactionData={selectedTransaction}
                    />
                </Modal>


                <div style={{ overflowX: "auto" }}>
                    {view === 'cards' && <ActividadesTable3
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'table' && <ActividadesTable
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'calendar' && <ActividadesCalendario
                        actividades={actividades}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                </div>
            </Card>
        </div>
    );
}

export default ListadoActividades;
