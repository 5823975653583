import React from 'react';
import { Text, Button, Modal, List, Group, rem, Divider, Accordion } from '@mantine/core';
import { useForm } from '@mantine/form';
import { supabase } from '../supabaseClient';
import { IconMail, IconPhone, IconUsers, IconBuilding } from '@tabler/icons-react';


const ActividadesForm = ({ tratoId, nombreTrato, descripcionTrato, contactos }) => {
    const form = useForm({
        initialValues: {
            nombre_actividad: '',
            id_estatus_actividad: '',
            id_tipo_actividad: '',
        },
    });

    return (
        <div>
            {contactos && contactos.length > 0 && (
                <div className="referencia">
                    <Text fw={700} mb={'10px'} size="sm">{nombreTrato}</Text>
                    <Text size="sm" mb={'10px'}>{descripcionTrato}</Text>
                    <Accordion defaultValue="catalogo">
                        <Accordion.Item value="catalogo">
                            <Accordion.Control className="AccordionControl">
                                <Group>
                                    <IconUsers style={{ width: rem(16), height: rem(16) }} />
                                    <Text size="md">Contactos asociados:</Text>
                                </Group>
                            </Accordion.Control>
                            <Accordion.Panel className="AccordionPanel" >
                                <List spacing="xs" size="sm" center>
                                    <List withPadding listStyleType="inherit">
                                        {contactos.map(contacto => (
                                            <div key={contacto.id_contacto} className="contacto-item" style={{ padding: '10px 0' }}>
                                                <Group direction="column" spacing={5}>
                                                    <Text weight={500} size="sm">{contacto.nombre_contacto}</Text>
                                                    <Group spacing={5}>
                                                        <IconMail size={16} />
                                                        <Text size="sm" color="dimmed">{contacto.correo_contacto}</Text>
                                                    </Group>
                                                    <Group spacing={5}>
                                                        <IconPhone size={16} />
                                                        <Text size="sm" color="dimmed">{contacto.celular_contacto}</Text>
                                                    </Group>
                                                    <Group spacing={5}>
                                                        <IconBuilding size={16} />
                                                        <Text size="sm" color="dimmed">{contacto.nombre_corto_empresa}</Text>
                                                    </Group>
                                                </Group>
                                                <Divider style={{ margin: '10px 0' }} />
                                            </div>
                                        ))}
                                    </List>
                                </List>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </div>
            )}
        </div>


    );
};

export default ActividadesForm;
