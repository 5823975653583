import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import EstadosTratosTable from '../components/EstadosTratosTable';
import EstadosTratosForm from '../components/EstadosTratosForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";

function CatalogoEstadosTratos({ user, permisos }) {
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [estadostratos, setEstadosTratos] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const dispatch = useDispatch();
    const [filtroEstadoTrato, setFiltroEstadoTrato] = useState('');

    useEffect(() => {
        const getEstadosTratos = async () => {
            try {
                dispatch(ShowLoading());
                const allEstadosTratos = await fetchEstadosTratos();
                setEstadosTratos(allEstadosTratos); // Populate the React state
                dispatch(HideLoading());
            } catch (error) {
                dispatch(HideLoading());
                showNotification({
                    title: "Error obteniendo información",
                    color: "red"
                });
            }
        };
        getEstadosTratos();
    }, []);

    const fetchEstadosTratos = async () => {
        try {
            let { data: estadotratos, error } = await supabase
                .from('cat_estados_tratos')
                .select(`
                    id_estado_trato,
                    nombre_estado_trato
                `)
                .order('id_estado_trato', { ascending: true });

            if (error) {
                throw error;
            }
            return estadotratos;
        } catch (error) {
            throw error;
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: estadostratosFiltrados, error } = await supabase
                .from('cat_estados_tratos')
                .select(`
                    id_estado_trato,
                    nombre_estado_trato
                `)
                .ilike('nombre_estado_trato', `%${filtroEstadoTrato.toLowerCase()}%`)
                .order('id_estado_trato', { ascending: true });

            if (error) {
                throw error;
            }
            setEstadosTratos(estadostratosFiltrados);
        } catch (error) {
            showNotification({
                title: "Error al filtrar estados trato",
                color: "red"
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };

    const getData = async () => {
        try {
            const allEstadosTratos = await fetchEstadosTratos();
            setEstadosTratos(allEstadosTratos);
        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const canAddEstadoTrato = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_estados_tratos &&
        permisos.catalogos.modulos.cat_estados_tratos.acciones &&
        permisos.catalogos.modulos.cat_estados_tratos.acciones.registrar_estado_trato === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar estado trato"
                                value={filtroEstadoTrato}
                                onChange={(event) => setFiltroEstadoTrato(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddEstadoTrato && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}>
                                    + Agregar Estado Trato
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Estado Trato" : "Editar Estado Trato"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <EstadosTratosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar estadotrato seleccionada como prop
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <EstadosTratosTable
                        estadostratos={estadostratos} // Cambiado a estadostratos
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        filtroEstadoTrato={filtroEstadoTrato}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoEstadosTratos;
