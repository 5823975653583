import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, NativeSelect } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";



function ContactosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    //const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_contacto: "",
        id_contacto: "", // Inicializar id_contacto como una cadena vacía
        correo_contacto: "",
        celular_contacto: "",
        puesto_contacto: "",
        id_empresa: "",
        nombre_corto_empresa: "",
        notas_contacto:"",

    })

    const [empresaFilter, setEmpresaFilter] = useState(""); //para el filtro para buscar empresa


    // Este useEffect es porque en CatalogoContratante ya tomo el valor de id_tipo_contratante, entonces
    // este codigo evita que lo ponga en "" y me marque un warning
    useEffect(() => {
        if (!user.id_contacto) {
            setUser({
                nombre_contacto: "",
                id_contacto: "", // Inicializar id_contacto como una cadena vacía
                correo_contacto: "",
                celular_contacto: "",
                puesto_contacto: "",
                id_empresa: "",
                nombre_corto_empresa: "",
                notas_contacto:"",


            });
        }
    }, [user.id_contacto]);

    useEffect(() => {
        async function fetchFilteredEmpresas() {
            const { data: filteredEmpresas } = await supabase
                .from('cat_empresas')
                .select('id_empresa, nombre_corto_empresa')
                .ilike('nombre_corto_empresa', `%${empresaFilter}%`)
                .order('nombre_corto_empresa', { ascending: true });
            setTiposEmpresas(filteredEmpresas || []);
        }

        if (empresaFilter) {
            fetchFilteredEmpresas();
        } else {
            async function fetchAllEmpresas() {
                const { data: empresas } = await supabase.from('cat_empresas').select('id_empresa, nombre_corto_empresa').order('nombre_corto_empresa', { ascending: true });
                setTiposEmpresas(empresas || []);
            }
            fetchAllEmpresas();
        }
    }, [empresaFilter]);

    // Codigo para agregar al select las opciones de tipo de contratante, segun cat_tipo_contratante
    const [selectedEmpresa, setSelectedEmpresa] = useState(""); //se pone persona fisica por default al agregar contratante
    const [tiposEmpresas, setTiposEmpresas] = useState([]);


    useEffect(() => {
        async function fetchTiposEmpresas() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_empresas')
                    .select('id_empresa, nombre_corto_empresa');

                setTiposEmpresas(tipos);
            } catch (error) {
            }
        }
        fetchTiposEmpresas();
    }, []);


    useEffect(() => {
        console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_contacto: transactionData.nombre_contacto || "",
                id_contacto: transactionData.id_contacto || "",
                correo_contacto: transactionData.correo_contacto || "",
                celular_contacto: transactionData.celular_contacto || "",
                puesto_contacto: transactionData.puesto_contacto || "",
                notas_contacto: transactionData.notas_contacto || "",

                //nombre_corto_empresa:transactionData.nombre_corto_empresa || "",
            });
            setSelectedEmpresa(transactionData.id_empresa || "");
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangeSelect = (event) => {
        setSelectedEmpresa(event.target.value);
        console.log(event.target.value);
    };

    const handleUpdate = async () => {

        try {
            await supabase
                .from('cat_contactos')
                .update({
                    nombre_contacto: user.nombre_contacto,
                    correo_contacto: user.correo_contacto,
                    celular_contacto: user.celular_contacto,
                    puesto_contacto: user.puesto_contacto,
                    id_empresa: selectedEmpresa,
                    notas_contacto: user.notas_contacto,

                })
                .eq('id_contacto', user.id_contacto); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Contacto actualizado',
                message: 'El contacto se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el contacto:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el contacto.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('cat_contactos')
                    .insert({
                        nombre_contacto: user.nombre_contacto,
                        correo_contacto: user.correo_contacto,
                        celular_contacto: user.celular_contacto,
                        puesto_contacto: user.puesto_contacto,
                        notas_contacto: user.notas_contacto,

                        id_empresa: selectedEmpresa,
                    });
                showNotification({
                    title: 'Contacto agregado',
                    message: 'El contacto ha sido agregado con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdate();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            console.error('Error al agregar/actualizar contacto:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar contacto.',
                color: 'red',
            });
        }
    };

    const handleEmpresaFilterChange = (event) => {
        setEmpresaFilter(event.target.value);
    };


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <Group >

                            <TextInput
                                disabled
                                name="id_contacto"
                                label="ID"
                                onChange={handleChange}
                                className='caja-350'
                                value={user.id_contacto}

                            />

                            <TextInput
                                name="nombre_contacto"
                                label="Nombre Contacto"
                                className='caja-350'
                                required
                                onChange={handleChange}
                                value={user.nombre_contacto} // Asignar el valor del estado al campo
                                placeholder='Nombre contacto'

                            />
                        </Group>
                        <Group >

                            <TextInput
                                name="celular_contacto"
                                label="Celular"
                                onChange={handleChange}
                                className='caja-350'
                                value={user.celular_contacto}

                            />

                            <TextInput
                                name="correo_contacto"
                                label="Correo"
                                className='caja-350'
                                required
                                onChange={handleChange}
                                value={user.correo_contacto} // Asignar el valor del estado al campo
                                placeholder='Correo'

                            />
                        </Group>
                        <Group >

                            <TextInput

                                name="puesto_contacto"
                                label="Puesto"
                                onChange={handleChange}
                                className='caja-350'
                                value={user.puesto_contacto}

                            />
                            <TextInput
                                name="notas_contacto"
                                label="Notas"
                                onChange={handleChange}
                                className='caja-350'
                                value={user.notas_contacto}

                            />
                        </Group>
                        <Group>
                            <TextInput
                                label="Filtrar empresas"
                                value={empresaFilter}
                                onChange={handleEmpresaFilterChange}
                                className='caja-350'
                            />

                            <NativeSelect
                                name="id_empresa"
                                label="Empresa"
                                className='caja-350'
                                value={selectedEmpresa}
                                onChange={handleChangeSelect}
                                placeholder=''
                                required

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposEmpresas.map((tipo) => (
                                    <option key={tipo.id_empresa} value={tipo.id_empresa}>
                                        {tipo.nombre_corto_empresa}
                                    </option>
                                ))}
                            </NativeSelect>
                        </Group>



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Contacto" : "Editar Contacto"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ContactosForm;