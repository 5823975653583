import React, { useState } from 'react';
import { Card, Button, Group, Pagination, Text, Spoiler, Badge, Timeline, Stack } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { IconSpy, IconPhone, IconCalendar, IconId, IconBrandZoom, IconMail, IconBrandWhatsapp, IconNote } from '@tabler/icons-react';

function ActividadesTable3({ actividades, setSelectedTransaction, setFormMode, setShowForm, getData, filtroActividad, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;

    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const [estatusActividades, setEstatusActividades] = useState([]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const actividadesFiltradas = filtroActividad
        ? actividades.filter(actividad => actividad.nombre_actividad.toLowerCase().includes(filtroActividad.toLowerCase()))
        : actividades;

    const paginatedTransactions = actividadesFiltradas.slice(startIndex, endIndex);

    const getIconByTipoActividad = (tipo) => {
        if (tipo == null) return <IconNote size={20} />;
        const tipoStr = tipo.toString();
        switch (tipoStr) {
            case '1':
                return <IconMail size={20} />;
            case '2':
                return <IconBrandWhatsapp size={20} />;
            case '3':
                return <IconNote size={20} />;
            case '4':
                return <IconSpy size={20} />;
            case '5':
                return <IconBrandZoom size={20} />;
            case '6':
                return <IconPhone size={20} />;
            default:
                return <IconNote size={20} />;
        }
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_actividades')
                .delete()
                .eq('id_actividad', id);

            if (error) {
                throw new Error('No se pudo borrar la actividad');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Actividad eliminada',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la actividad',
                color: 'red'
            });
        }
    };

    const duplicateTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Obtener la actividad actual
            const { data, error } = await supabase
                .from('cat_actividades')
                .select('*')
                .eq('id_actividad', id)
                .single();

            if (error || !data) {
                throw new Error('No se pudo obtener la actividad');
            }

            // Crear una nueva actividad duplicada
            delete data.id_actividad; // Eliminar el ID para insertar como nuevo
            const { error: insertError } = await supabase
                .from('cat_actividades')
                .insert([data]);

            if (insertError) {
                throw new Error('No se pudo duplicar la actividad');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Actividad duplicada',
                color: 'blue'
            });

            getData(); // Obtener nuevamente los datos después de la duplicación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo duplicar la actividad',
                color: 'red'
            });
        }
    };

    return (
        <div>
            <div style={{ gap: '15px', marginBottom: '10px', padding: '5px' }}>
                <Timeline lineWidth={3} bulletSize={40} align="left" color='black'>
                    {paginatedTransactions.map((actividad) => (
                        <Timeline.Item
                            key={actividad.id_actividad}
                            lineVariant='dashed'
                            bullet={getIconByTipoActividad(actividad.id_tipo_actividad)}
                            style={{ marginBottom: '30px' }}
                        >
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom:'5px' }}>
                                <Group>
                                    {actividad.id_estatus_actividad === 1 ? (
                                        <Badge color="green" variant="filled">
                                            Completada
                                        </Badge>
                                    ) : actividad.id_estatus_actividad === 2 ? (
                                        <Badge color="red" variant="filled">
                                            Pendiente
                                        </Badge>
                                    ) : (
                                        <Badge color="blue" variant="filled">
                                            {estatusActividades.find(e => e.id_estatus_actividad === actividad.id_estatus_actividad)?.nombre_estatus_actividad}
                                        </Badge>
                                    )}
                                    {actividad.nombre_actividad}
                                </Group>
                            </div>
                            <Group>

                                <div>
                                    <Group>
                                        <Text color="dimmed" size="sm">
                                            <IconId size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            ID: {actividad.id_actividad}
                                        </Text>
                                        <Text color="dimmed" size="sm">
                                            <IconCalendar size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            {moment(actividad.fecha_actividad).format('LLL')}
                                        </Text>
                                    </Group>
                                    <Group>
                                        <Text color="dimmed" size="sm">
                                            <IconId size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            Empresa: {actividad.cat_tratos.cat_empresas.nombre_corto_empresa}
                                        </Text>
                                        <Text color="dimmed" size="sm">
                                            <IconId size={20} style={{ marginBottom: '-4px', marginRight: '2px' }} />
                                            Trato: {actividad.cat_tratos.nombre_trato}
                                        </Text>
                                    </Group>

                                </div>
                                {permisos?.catalogos?.modulos?.cat_actividades?.acciones?.modificar_actividad && (
                                    <i
                                        key={`edit-${actividad.id_actividad}`}
                                        className='ri-pencil-line'
                                        onClick={() => {
                                            setSelectedTransaction(actividad);
                                            setFormMode("edit");
                                            setShowForm(true);
                                        }}
                                    ></i>
                                )}
                                {permisos?.catalogos?.modulos?.cat_actividades?.acciones?.modificar_actividad && (
                                    <i
                                        key={`duplicate-${actividad.id_actividad}`}
                                        className='ri-file-copy-line'
                                        onClick={() => {
                                            duplicateTransaction(actividad.id_actividad);
                                        }}
                                    ></i>

                                )}
                                {permisos?.catalogos?.modulos?.cat_actividades?.acciones?.eliminar_actividad && (
                                    <i
                                        key={`delete-${actividad.id_actividad}`}
                                        className='ri-delete-bin-line'
                                        onClick={() => {
                                            if (window.confirm('¿Estás seguro de eliminar esta actividad?')) {
                                                deleteTransaction(actividad.id_actividad);
                                            }
                                        }}
                                    ></i>
                                )}
                            </Group>

                            <Spoiler maxHeight={100} showLabel="Leer más" hideLabel="Ver menos" transitionDuration={500}>
                                <Text size='sm' mt={4} style={{ whiteSpace: 'pre-line' }}>
                                    {actividad.descripcion_actividad}
                                </Text>
                            </Spoiler>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </div>
            <Group justify="flex-end" mb={10} mt={20}>
                <Pagination
                    total={Math.ceil(actividades.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'actividades.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={actividades.map(({ id_actividad, nombre_actividad }) => ({
                        id_actividad,
                        nombre_actividad
                    }))}
                    columns={[
                        { id: 'id_actividad', displayName: 'ID' },
                        { id: 'nombre_actividad', displayName: 'Nombre actividad' },
                    ]}
                >
                    <Button variant="light" color='#00497A'>Descargar Datos</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ActividadesTable3;
