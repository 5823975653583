import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function ContactosTable({ contactos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroContacto, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    
    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del contacto si se ha proporcionado un filtro
    const contactosFiltrados = filtroContacto
        ? contactos.filter(contacto => contacto.nombre_contacto.toLowerCase().includes(filtroContacto.toLowerCase()))
        : contactos;

    const paginatedTransactions = contactosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_contactos')
                .delete()
                .eq('id_contacto', id);

            if (error) {
                throw new Error('No se pudo borrar el contacto');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Contacto eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el contacto',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((contacto) => (
        <tr key={contacto.id_contacto}>
            <td>{contacto.id_contacto}</td>
            <td>{truncateText(contacto.nombre_contacto,50)}</td>
            <td>{contacto.celular_contacto}</td>
            <td>{truncateText(contacto.correo_contacto,39)}</td>
            <td>{truncateText(contacto.puesto_contacto, 35)}</td>
            <td>{truncateText(contacto.cat_empresas.nombre_corto_empresa,30)}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_contactos &&
                        permisos.catalogos.modulos.cat_contactos.acciones &&
                        permisos.catalogos.modulos.cat_contactos.acciones.modificar_contacto && (
                            <i
                                key={`edit-${contacto.id_contacto}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(contacto);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_contactos &&
                        permisos.catalogos.modulos.cat_contactos.acciones &&
                        permisos.catalogos.modulos.cat_contactos.acciones.eliminar_contacto && (
                            <i
                                key={`delete-${contacto.id_contacto}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este contacto?')) {
                                        deleteTransaction(contacto.id_contacto);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre contacto',
    ];

    const csvData = contactos.map((contacto) => [
        contacto.id_contacto,
        contacto.nombre_contacto,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Celular</th>
                        <th>Correo</th>
                        <th>Puesto</th>
                        <th>Empresa</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(contactos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'contactos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ContactosTable;
