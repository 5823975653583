import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function CategoriasTratosTable({ categoriastratos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroCategoriaTrato, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    
    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del categoriatrato si se ha proporcionado un filtro
    const categoriastratosFiltrados = filtroCategoriaTrato
        ? categoriastratos.filter(categoriatrato => categoriatrato.nombre_categoria_trato.toLowerCase().includes(filtroCategoriaTrato.toLowerCase()))
        : categoriastratos;

    const paginatedTransactions = categoriastratosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_categorias_tratos')
                .delete()
                .eq('id_categoria_trato', id);

            if (error) {
                throw new Error('No se pudo borrar la categoria trato');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'categoria trato eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el categoria trato',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((categoriatrato) => (
        <tr key={categoriatrato.id_categoria_trato}>
            <td>{categoriatrato.id_categoria_trato}</td>

            <td>{truncateText(categoriatrato.nombre_categoria_trato ? categoriatrato.nombre_categoria_trato : '',35)}</td>

            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_categorias_tratos &&
                        permisos.catalogos.modulos.cat_categorias_tratos.acciones &&
                        permisos.catalogos.modulos.cat_categorias_tratos.acciones.modificar_categoria_trato && (
                            <i
                                key={`edit-${categoriatrato.id_categoria_trato}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(categoriatrato);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_categorias_tratos &&
                        permisos.catalogos.modulos.cat_categorias_tratos.acciones &&
                        permisos.catalogos.modulos.cat_categorias_tratos.acciones.eliminar_categoria_trato && (
                            <i
                                key={`delete-${categoriatrato.id_categoria_trato}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este categoria trato?')) {
                                        deleteTransaction(categoriatrato.id_categoria_trato);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre categoria trato',
    ];

    const csvData = categoriastratos.map((categoriatrato) => [
        categoriatrato.id_categoria_trato,
        categoriatrato.nombre_categoria_trato,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre categoría del trato</th>
                        


                        
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(categoriastratos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'categoriastratos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default CategoriasTratosTable;
