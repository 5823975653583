import React, { useState } from 'react';
import { TextInput, Button, Group, Select } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

const ActividadesFiltro = ({ onFilter, estatusActividades }) => {
    const [filtroFechaInicial, setFiltroFechaInicial] = useState(null);
    const [filtroFechaFinal, setFiltroFechaFinal] = useState(null);
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroDescripcion, setFiltroDescripcion] = useState('');
    const [filtroEstatus, setFiltroEstatus] = useState('');

    const handleFilter = () => {
        const filtro = {
            fechainicial: filtroFechaInicial,
            fechafinal: filtroFechaFinal,
            nombre: filtroNombre,
            descripcion: filtroDescripcion,
            estatus: filtroEstatus,
        };

        onFilter(filtro);
    };

    const handleFechaInicialChange = (newValue) => {
        setFiltroFechaInicial(newValue);
    };

    const handleFechaFinalChange = (newValue) => {
        setFiltroFechaFinal(newValue);
    };

    return (
        <div className='FiltroActividades'>
            <DateTimePicker
                label="F. inicial"
                className='caja-150'
                value={filtroFechaInicial}
                onChange={handleFechaInicialChange}
            />
            <DateTimePicker
                label="F. final"
                className='caja-150'
                value={filtroFechaFinal}
                onChange={handleFechaFinalChange}
            />
            <TextInput
                label="Buscar nombre de actividad"
                placeholder="Nombre de actividad"
                value={filtroNombre}
                onChange={(event) => setFiltroNombre(event.target.value)}
            />
            <TextInput
                label="Buscar descripción de actividad"
                placeholder="Descripción de actividad"
                value={filtroDescripcion}
                onChange={(event) => setFiltroDescripcion(event.target.value)}
            />
            <Select
                label="Filtrar por estatus"
                placeholder="Seleccione estatus"
                className='caja-150'
                data={[
                    { value: '', label: 'Todos' },
                    ...estatusActividades.map(estatus => ({
                        value: estatus.id_estatus_actividad.toString(),
                        label: estatus.nombre_estatus_actividad
                    }))
                ]}
                value={filtroEstatus}
                onChange={(value) => setFiltroEstatus(value)}
            />
            <Button
                onClick={handleFilter}
                className='BotonAplicarFiltrosActividades'
            >
                Aplicar Filtros
            </Button>
        </div>
    );
};

export default ActividadesFiltro;
