import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import EtapasTratosTable from '../components/EtapasTratosTable';
import EtapasTratosForm from '../components/EtapasTratosForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";

function CatalogoEtapasTratos({ user, permisos }) {
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [etapastratos, setEtapasTratos] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const dispatch = useDispatch();
    const [filtroEtapaTrato, setFiltroEtapaTrato] = useState('');

    useEffect(() => {
        const getEtapasTratos = async () => {
            try {
                dispatch(ShowLoading());
                const allEtapasTratos = await fetchEtapasTratos();
                setEtapasTratos(allEtapasTratos); // Populate the React state
                dispatch(HideLoading());
            } catch (error) {
                dispatch(HideLoading());
                showNotification({
                    title: "Error obteniendo información",
                    color: "red"
                });
            }
        };
        getEtapasTratos();
    }, []);

    const fetchEtapasTratos = async () => {
        try {
            let { data: etapatratos, error } = await supabase
                .from('cat_etapas_tratos')
                .select(`
                    id_etapa_trato,
                    nombre_etapa_trato
                `)
                .order('id_etapa_trato', { ascending: true });

            if (error) {
                throw error;
            }
            return etapatratos;
        } catch (error) {
            throw error;
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: etapastratosFiltrados, error } = await supabase
                .from('cat_etapas_tratos')
                .select(`
                    id_etapa_trato,
                    nombre_etapa_trato
                `)
                .ilike('nombre_etapa_trato', `%${filtroEtapaTrato.toLowerCase()}%`)
                .order('id_etapa_trato', { ascending: true });

            if (error) {
                throw error;
            }
            setEtapasTratos(etapastratosFiltrados);
        } catch (error) {
            showNotification({
                title: "Error al filtrar etapas trato",
                color: "red"
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };

    const getData = async () => {
        try {
            const allEtapasTratos = await fetchEtapasTratos();
            setEtapasTratos(allEtapasTratos);
        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const canAddEtapaTrato = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_etapas_tratos &&
        permisos.catalogos.modulos.cat_etapas_tratos.acciones &&
        permisos.catalogos.modulos.cat_etapas_tratos.acciones.registrar_etapa_trato === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar etapa trato"
                                value={filtroEtapaTrato}
                                onChange={(event) => setFiltroEtapaTrato(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddEtapaTrato && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}>
                                    + Agregar Etapa Trato
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Etapa Trato" : "Editar Etapa Trato"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <EtapasTratosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar etapatrato seleccionada como prop
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <EtapasTratosTable
                        etapastratos={etapastratos} // Cambiado a etapastratos
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        filtroEtapaTrato={filtroEtapaTrato}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoEtapasTratos;
