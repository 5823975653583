import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function EtapasTratosTable({ etapastratos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEtapaTrato, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    
    const truncateText = (text, maxLength) => {
        return text && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del etapatrato si se ha proporcionado un filtro
    const etapastratosFiltrados = filtroEtapaTrato
        ? etapastratos.filter(etapatrato => etapatrato.nombre_etapa_trato.toLowerCase().includes(filtroEtapaTrato.toLowerCase()))
        : etapastratos;

    const paginatedTransactions = etapastratosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_etapas_tratos')
                .delete()
                .eq('id_etapa_trato', id);

            if (error) {
                throw new Error('No se pudo borrar el etapa trato');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Etapa Trato eliminado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el etapa trato',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((etapatrato) => (
        <tr key={etapatrato.id_etapa_trato}>
            <td>{etapatrato.id_etapa_trato}</td>
            <td>{truncateText(etapatrato.nombre_etapa_trato,50)}</td>
            
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_etapas_tratos &&
                        permisos.catalogos.modulos.cat_etapas_tratos.acciones &&
                        permisos.catalogos.modulos.cat_etapas_tratos.acciones.modificar_etapa_trato && (
                            <i
                                key={`edit-${etapatrato.id_etapa_trato}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(etapatrato);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_etapas_tratos &&
                        permisos.catalogos.modulos.cat_etapas_tratos.acciones &&
                        permisos.catalogos.modulos.cat_etapas_tratos.acciones.eliminar_etapa_trato && (
                            <i
                                key={`delete-${etapatrato.id_etapa_trato}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este etapa trato?')) {
                                        deleteTransaction(etapatrato.id_etapa_trato);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre etapatrato',
    ];

    const csvData = etapastratos.map((etapatrato) => [
        etapatrato.id_etapa_trato,
        etapatrato.nombre_etapa_trato,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre Etapa Trato</th>
                        
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(etapastratos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'etapastratos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default EtapasTratosTable;
