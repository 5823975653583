import '@mantine/core/styles.css';
import { useSelector } from "react-redux";
import React from 'react';
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'; // Importa BrowserRouter y Routes
import { Provider } from 'react-redux';
import store from './redux/store';
import MainRouter from './components/MainRouter';
import Spinner from "./components/Spinner";
import { AppShellWithNavbar } from './components/AppShell';
import Login from './pages/Auth/Login'; // Importa el componente de Login
import PublicRoute from './components/PublicRoute';
import moment from 'moment';
import 'moment/locale/es'; // Importar el locale español

moment.locale('es'); // Configurar moment para usar español

function App() {
    const { loading } = useSelector((state) => state.alerts);
    return (
        <div className="App">
            {loading && (
                <div className="loader-parent">
                    <Spinner />
                </div>
            )}

            <AuthProvider>
                <BrowserRouter>
                    <Provider store={store}>
                        {/* Usa Routes para definir las rutas */}
                        <Routes>
                            {/* Ruta para el inicio de sesión */}
                            <Route
                                path="/login"
                                element={<PublicRoute element={<Login />} />}
                            />


                            {/* Ruta para el resto de la aplicación */}
                            <Route
                                path="/*"
                                element={ // Utiliza AppShellWithNavbar solo cuando esté autenticado
                                    <AppShellWithNavbar>
                                        <MainRouter />
                                    </AppShellWithNavbar>
                                }
                            />
                        </Routes>
                    </Provider>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
