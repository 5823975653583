import React, { useEffect, useState } from 'react';
import { AppShell, Burger, Group, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import SideNavbar from './SideNavbar';
import { useAuth } from '../context/AuthContext';
import { IconBuildingFactory2, IconAddressBook, IconCoin, IconCalendarDue } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Para el enlace de navegación

import Spinner from './Spinner';
import '../index.css';

export function AppShellWithNavbar({ children }) {
  const { user, signOut, loading } = useAuth();
  const [permisos, setPermisos] = useState([]);
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const fetchUserPermissions = async () => {
        try {
          dispatch(ShowLoading());
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('acciones')
            .eq('id', user.id)
            .single();

          if (error) {
            throw error;
          }

          if (profile) {
            const userPermissions = profile.acciones || {};
            setPermisos(userPermissions);
          }
        } catch (error) {
          console.error("Error fetching user permissions:", error.message);
        } finally {
          dispatch(HideLoading());
        }
      };

      fetchUserPermissions();
    }
  }, [user, dispatch]);

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };

  if (!user && !loading) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div className="loader-parent">
        <Spinner />
      </div>
    );
  }

  return (
    <AppShell
      layout='alt'
      header={{ height: 60 }}
      navbar={{
        width: 220,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      
      padding="md"
    >
      <AppShell.Header
        withBorder={false}
        style={{ backgroundColor: 'white' }}
      >
        <Group h="100%" justify="space-between" px="md">
          <Group>
            {/* Burger en el extremo izquierdo */}
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
            {!desktopOpened && (
              <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
            )}
          </Group>

          <Group>
            {user && (
              <Button onClick={handleSignOut} variant="outline" color="rgba(0, 0, 0, 1)">
                Cerrar sesión
              </Button>
            )}
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="sm" className='NavBarCard' style={{ backgroundColor: '#32323A' }}>
        <Group>
          {/* Burger en el extremo izquierdo */}
          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm" size="sm"
            color='white'
          />
          <Burger
            opened={desktopOpened}
            onClick={toggleDesktop}
            visibleFrom="sm" size="sm"
            color='white'

          />
          <h3 style={{ color: 'white' }} >CRM insadisa</h3>
        </Group>
        <SideNavbar user={user} permisos={permisos} />
      </AppShell.Navbar>

      <AppShell.Main style={{ backgroundColor: '#f0f0f0' }}>{children}</AppShell.Main>
      
    </AppShell>
  );
}
