import React, { useState } from 'react';
import BarChartTratosGanados from '../components/BarChartTratosGanados';
import BarChartTratosGanadosFiltro from '../components/BarChartTratosGanadosFiltro';

const CatalogoReporteBarrasGanados = () => {
  const [filtro, setFiltro] = useState(null);

  const handleFilter = (filtro) => {
    setFiltro(filtro);
  };

  return (
    <div>
      <BarChartTratosGanadosFiltro onFilter={handleFilter} />
      {filtro && <BarChartTratosGanados filtro={filtro} />}
    </div>
  );
};

export default CatalogoReporteBarrasGanados;