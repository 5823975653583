import React, { useEffect, useState } from 'react';
import { Group, Code, ScrollArea, rem, Text, TypographyStylesProvider, Divider } from '@mantine/core';
import {
    IconReportMoney,
    IconClipboardText,
    IconHome,
    IconBook2,
    IconReportAnalytics,
    IconAdjustments,
    IconLock,
} from '@tabler/icons-react';
import { LinksGroup } from './LinksGroup';
import classes from './NavbarNested.module.css';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';
import { UserButton } from './UserButton';
import Spinner from './Spinner';

const mockdata = [
    {
        label: 'Inicio',
        icon: IconHome,
        //initiallyOpened: true,
        links: [
            { label: 'Ir a inicio', link: '/home' },
        ],
    }, {
        label: 'Catálogos',
        icon: IconBook2,
        //initiallyOpened: true,
        links: [
            { label: 'Categorías Tratos', link: '/cat_categoriastratos' },
            { label: 'Estados Tratos', link: '/cat_estadostratos' },
            { label: 'Etapas Tratos', link: '/cat_etapastratos' },
            { label: 'Fuentes Tratos', link: '/cat_fuentes' },
            { label: 'Tipos Actividades', link: '/cat_tiposactividades' },

            { label: 'Empresas', link: '/cat_empresas' },
            { label: 'Contactos', link: '/cat_contactos' },
            { label: 'Tratos', link: '/cat_tratos' },


        ],
    },
    
    {
        label: 'Reportes',
        icon: IconReportAnalytics,
        links: [
            { label: '1. Actividades', link: '/reporte_actividades' },
            { label: '2. Tratos Ganados', link: '/reporte_tratosganados' },

            
        ],
    },
];

export function SideNavbar({ user, permisos }) {
    const [loaded, setLoaded] = useState(false); // Estado local para controlar la carga de permisos
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && permisos) {
            // Verificar si los permisos y el usuario están disponibles
            setLoaded(true); // Marcar que los permisos han sido cargados
        }
    }, [user, permisos]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    useEffect(() => {
        // Mostrar indicador de carga mientras se revisan los permisos
        if (!loaded) {
            dispatch(ShowLoading());
        } else {
            // Ocultar indicador de carga una vez que se han revisado los permisos
            dispatch(HideLoading());
        }
    }, [loaded, dispatch]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    if (!loaded) {
        return null;
        //dispatch(ShowLoading());
    }

   

    const menuItems = mockdata.map((item) => {
        if (item.label === 'Inicio') {
            return <LinksGroup {...item} key={item.label} />;
        }
        if (item.label === 'Catálogos') {
            // Filtrar elementos del catálogo
            const catalogoItems = item.links.filter((link) => {
                // Obtener el nombre del catálogo del enlace
                const catalogo = link.link.split('/')[1];
                // Verificar si el catálogo está presente en los permisos y si está activo
                return !permisos ||
                    !permisos.catalogos ||
                    !permisos.catalogos.modulos ||
                    !permisos.catalogos.modulos[catalogo] ||
                    permisos.catalogos.modulos[catalogo].activo === true;
            });
            //console.log("Catálogo Items:", catalogoItems);

            // Si no hay elementos en el catálogo, no mostrarlo
            if (catalogoItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces del catálogo con los elementos filtrados
            return <LinksGroup {...item} links={catalogoItems} key={item.label} />;
        }
        if (item.label === 'Pólizas') {
            // Filtrar elementos de pólizas
            const polizasItems = item.links.filter((link) => {
                // Verificar si la póliza está presente en los permisos y si está activa
                return permisos &&
                    permisos.polizas &&
                    permisos.polizas.activo === true;
            });
            //console.log("Pólizas Items:", polizasItems);

            // Si no hay elementos en pólizas, no mostrarlo
            if (polizasItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces de pólizas con los elementos filtrados
            return <LinksGroup {...item} links={polizasItems} key={item.label} />;
        }
        if (item.label === 'Pagos') {
            // Filtrar elementos de pagos
            const pagosItems = item.links.filter((link) => {
                // Verificar si la pagos está presente en los permisos y si está activa
                return permisos &&
                    permisos.pagos &&
                    permisos.pagos.activo === true;
            });
            //console.log("pagos Items:", pagosItems);

            // Si no hay elementos en pólizas, no mostrarlo
            if (pagosItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces de pólizas con los elementos filtrados
            return <LinksGroup {...item} links={pagosItems} key={item.label} />;
        }

        if (item.label === 'Reportes') {
            // Filtrar elementos del catálogo
            const reporteItems = item.links.filter((link) => {
                // Obtener el nombre del catálogo del enlace
                const reporte = link.link.split('/')[1];
                // Verificar si el catálogo está presente en los permisos y si está activo
                return permisos &&
                    permisos.reportes &&
                    permisos.reportes.modulos &&
                    permisos.reportes.modulos[reporte] &&
                    permisos.reportes.modulos[reporte].activo === true;
            });
            //console.log("Reporte Items:", reporteItems);

            // Si no hay elementos en el catálogo, no mostrarlo
            if (reporteItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces del catálogo con los elementos filtrados
            return <LinksGroup {...item} links={reporteItems} key={item.label} />;
        }

        // Si no es el catálogo ni 'Polizas', simplemente retornar el grupo de enlaces
        return <LinksGroup {...item} key={item.label} />;
    });



    return (
        <nav className={classes.navbar}>
            <div className={classes.header}>
                <UserButton></UserButton>
            </div>
            <ScrollArea style={{ maxHeight: 'calc(100vh - 310px)' }} className={classes.links}>
                <div className={classes.linksInner}>{menuItems}</div>
            </ScrollArea>

            
        </nav>
    );
}

export default SideNavbar;