import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CatalogoContactos from '../pages/CatalogoContactos';
import CatalogoEmpresas from '../pages/CatalogoEmpresas';
import CatalogoTratos from '../pages/CatalogoTratos';
import CatalogoActividades from '../pages/CatalogoActividades';
import CatalogoCategoriasTratos from '../pages/CatalogoCategoriasTratos';
import CatalogoEstadosTratos from '../pages/CatalogoEstadosTratos';
import CatalogoEtapasTratos from '../pages/CatalogoEtapasTratos';
import CatalogoFuentes from '../pages/CatalogoFuentes';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import Login from '../pages/Auth/Login';
import CatalogoTiposActividades from '../pages/CatalogoTiposActividades';
import CatalogoReporteBarrasGanados from '../pages/CatalogoReporteBarrasGanados';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';

const MainRouter = () => {

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);

                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        } else {
            //console.log("no hay usuario")
            dispatch(HideLoading());

        }
    }, [user, dispatch]);


    return (
        <div>
            <main>
                <Routes>
                    <Route path='/home' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_contactos &&
                        permisos.catalogos.modulos.cat_contactos.activo === true && (
                            <Route path='/cat_contactos' element={<ProtectedRoute><CatalogoContactos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_empresas &&
                        permisos.catalogos.modulos.cat_empresas.activo === true && (
                            <Route path='/cat_empresas' element={<ProtectedRoute><CatalogoEmpresas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tratos &&
                        permisos.catalogos.modulos.cat_tratos.activo === true && (
                            <Route path='/cat_tratos' element={<ProtectedRoute><CatalogoTratos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_categorias_tratos &&
                        permisos.catalogos.modulos.cat_categorias_tratos.activo === true && (
                            <Route path='/cat_categoriastratos' element={<ProtectedRoute><CatalogoCategoriasTratos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.reporte_actividades &&
                        permisos.reportes.modulos.reporte_actividades.activo === true && (
                            <Route path='/reporte_actividades' element={<ProtectedRoute><CatalogoActividades user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_empresas &&
                        permisos.catalogos.modulos.cat_empresas.activo === true && (
                            <Route path='/cat_tiposactividades' element={<ProtectedRoute><CatalogoTiposActividades user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estados_tratos &&
                        permisos.catalogos.modulos.cat_estados_tratos.activo === true && (
                            <Route path='/cat_estadostratos' element={<ProtectedRoute><CatalogoEstadosTratos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_etapas_tratos &&
                        permisos.catalogos.modulos.cat_etapas_tratos.activo === true && (
                            <Route path='/cat_etapastratos' element={<ProtectedRoute><CatalogoEtapasTratos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_fuentes &&
                        permisos.catalogos.modulos.cat_fuentes.activo === true && (
                            <Route path='/cat_fuentes' element={<ProtectedRoute><CatalogoFuentes user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.reporte_tratosganados &&
                        permisos.reportes.modulos.reporte_tratosganados.activo === true && (
                            <Route path='/reporte_tratosganados' element={<ProtectedRoute><CatalogoReporteBarrasGanados user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}


                    {/* Ruta /login, solo mostrar si el usuario no está autenticado */}
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />

                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

