import React, { useEffect, useState } from 'react';
import { Card, TextInput, Group, Button, Select, Textarea, Accordion, Text, Divider, List, rem, NativeSelect } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { DateTimePicker } from '@mantine/dates';
import { IconUsers, IconPhone, IconBuilding, IconId, IconBrandZoom, IconEdit, IconTrash, IconMail, IconBrandWhatsapp, IconNote } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function ActividadesForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
    idTrato // Añade idTrato como prop

}) {

    const dispatch = useDispatch();

    // Valores iniciales
    const [user, setUser] = useState({
        id_actividad: "",
        nombre_actividad: "",
        descripcion_actividad: "",
        fecha_actividad: new Date(),
        id_estatus_actividad: "",
        id_trato: "",
        id_tipo_actividad: "",
        nombre_tipo_actividad: "",

    });

    const [contactosRelacionados, setContactosRelacionados] = useState([]);

    // Función para convertir una cadena de fecha en un objeto Date
    const convertToDate = (fecha) => {
        if (!fecha) return null; // Manejo del caso cuando `fecha` es null o undefined
        return new Date(fecha);
    };

    const [tratosFilter, setTratosFilter] = useState(""); //para el filtro para buscar trato


    useEffect(() => {
        if (!user.id_actividad) {
            setUser({
                id_actividad: "",
                nombre_actividad: "",
                descripcion_actividad: "",
                fecha_actividad: new Date(),
                id_estatus_actividad: "",
                id_trato: "",
                id_tipo_actividad: "",
                nombre_tipo_actividad: "",

            });
        }
    }, [user.id_actividad, transactionData]);

    //para filtrar tratos:
    useEffect(() => {
        async function fetchFilteredTrato() {
            const { data: filteredTrato } = await supabase
                .from('cat_tratos')
                .select('id_trato, nombre_trato')
                .ilike('nombre_trato', `%${tratosFilter}%`)
                .order('nombre_trato', { ascending: true });
            setListadotratos(filteredTrato || []);
        }

        if (tratosFilter) {
            fetchFilteredTrato();
        } else {
            async function fetchAllTratos() {
                const { data: tratos } = await supabase.from('cat_tratos').select('id_trato, nombre_trato').order('nombre_trato', { ascending: true });
                setListadotratos(tratos || []);
            }
            fetchAllTratos();
        }
    }, [tratosFilter]);

    // Código para agregar al select las opciones de tipo de trato
    const [listadotratos, setListadotratos] = useState([]);
    const [tratoseleccionado, setTratoseleccionado] = useState("");

    const [listadoestatusactividad, setListadoestatusactividades] = useState([]);
    const [estatusactividadseleccionada, setEstatusactividadseleccionada] = useState("");

    const [listadotiposactividades, setListadotiposactividades] = useState([]);
    const [tipoactividadseleccionada, setTipoactividadseleccionada] = useState("");

    const [empresaSeleccionada, setEmpresaSeleccionada] = useState("");
    const [nombreTratoSeleccionado, setNombreTratoSeleccionado] = useState("");
    const [descripcionTratoSeleccionado, setDescripcionTratoSeleccionado] = useState("");


        useEffect(() => {
        async function fetchTiposTratos() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_tratos')
                    .select('id_trato, nombre_trato')
                    .order('nombre_trato', { ascending: true });

                setListadotratos(tipos || []);
            } catch (error) {
                console.error('Error fetching tipos tratos:', error);
            }
        }

        async function fetchEstatusActividades() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_estatus_actividades')
                    .select('id_estatus_actividad, nombre_estatus_actividad')
                    .order('id_estatus_actividad', { ascending: true });

                setListadoestatusactividades(tipos || []);
            } catch (error) {
                console.error('Error fetching estatus actividades:', error);
            }
        }

        async function fetchTiposActividades() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_tipos_actividades')
                    .select('id_tipo_actividad, nombre_tipo_actividad')
                    .order('nombre_tipo_actividad', { ascending: true });
                setListadotiposactividades(tipos || []);
            } catch (error) {
                console.error('Error fetching tipos actividades:', error);
            }
        }

        fetchTiposTratos();
        fetchEstatusActividades();
        fetchTiposActividades();
    }, []);

    useEffect(() => {
        async function fetchContactosRelacionados(idTrato) {
            console.log("Fetching contactos relacionados for idTrato:", idTrato); // Mensaje de depuración

            try {
                //dispatch(ShowLoading()); // Disparar la acción de carga

                const { data: contactos, error } = await supabase
                    .from('cat_tratos_contactos')
                    .select(`
                        id_trato_contacto,
                        id_contacto,
                        cat_contactos (
                            id_contacto, 
                            nombre_contacto,
                            correo_contacto,
                            celular_contacto,
                            puesto_contacto,
                            id_empresa,
                            cat_empresas (
                                id_empresa, 
                                nombre_corto_empresa
                            )
                        ),
                        id_trato,
                        cat_tratos (
                            id_trato,
                            nombre_trato,
                            descripcion_trato,
                            valor_trato,
                            id_empresa,
                            cat_empresas(id_empresa, nombre_corto_empresa)
                        )
                    `)
                    .eq('id_trato', idTrato);

                if (error) {
                    console.error("Error fetching contactos relacionados:", error);
                    return;
                }

                console.log('Contactos relacionados:', contactos); // Imprimir en consola los datos obtenidos
                setContactosRelacionados(contactos || []);

                //dispatch(HideLoading()); // Ocultar la acción de carga cuando termina
            } catch (error) {
                console.error('Error fetching contactos relacionados:', error);
            }
        }

        console.log("Transaction data:", transactionData);
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id_actividad: transactionData.id_actividad || "",
                nombre_actividad: transactionData.nombre_actividad || "",
                descripcion_actividad: transactionData.descripcion_actividad || "",
                fecha_actividad: transactionData.fecha_actividad || "",
                id_tipo_actividad: transactionData.cat_tipos_actividades ? transactionData.cat_tipos_actividades.id_tipo_actividad : "",
                nombre_tipo_actividad: transactionData.cat_tipos_actividades ? transactionData.cat_tipos_actividades.nombre_tipo_actividad : "",
            });

            if (transactionData.cat_tratos && listadotratos.length > 0) {
                const tratosEncontrados = listadotratos.find(t => t.id_trato === transactionData.cat_tratos.id_trato);
                console.log("Trato encontrado:", tratosEncontrados);

                if (tratosEncontrados) {
                    setTratoseleccionado(tratosEncontrados.id_trato);
                    fetchContactosRelacionados(tratosEncontrados.id_trato); // Llama a la función para obtener contactos relacionados
                    setDescripcionTratoSeleccionado(transactionData.cat_tratos.descripcion_trato);
                }
            }

            if (transactionData.cat_estatus_actividades && listadoestatusactividad.length > 0) {
                const estatusactividadesEncontradas = listadoestatusactividad.find(t => t.id_estatus_actividad === transactionData.cat_estatus_actividades.id_estatus_actividad);
                if (estatusactividadesEncontradas) {
                    setEstatusactividadseleccionada(estatusactividadesEncontradas.id_estatus_actividad);
                }
            }

            if (transactionData.cat_tipos_actividades && listadotiposactividades.length > 0) {
                const tiposactividadesEncontradas = listadotiposactividades.find(t => t.id_tipo_actividad === transactionData.cat_tipos_actividades.id_tipo_actividad);
                if (tiposactividadesEncontradas) {
                    setTipoactividadseleccionada(tiposactividadesEncontradas.id_tipo_actividad);
                }
            }
        }
    }, [formMode, transactionData, listadotratos, listadoestatusactividad, listadotiposactividades]);

    useEffect(() => {
        if (formMode === 'edit' && transactionData.cat_tratos && transactionData.cat_tratos.cat_empresas) {
            setEmpresaSeleccionada(transactionData.cat_tratos.cat_empresas.nombre_corto_empresa || "");
            setNombreTratoSeleccionado(transactionData.cat_tratos.nombre_trato || "");
            setDescripcionTratoSeleccionado(transactionData.cat_tratos.descripcion_trato || "");
        }
    }, [formMode, transactionData]);





    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handlers = {
        id_trato: setTratoseleccionado,
        id_estatus_actividad: setEstatusactividadseleccionada,
        id_tipo_actividad: setTipoactividadseleccionada,
    };
    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        const handler = handlers[name];
        if (handler) {
            handler(value);
        }
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdate = async () => {
        try {
            const { error } = await supabase
                .from('cat_actividades')
                .update({
                    id_trato: tratoseleccionado,
                    id_estatus_actividad: estatusactividadseleccionada,
                    id_tipo_actividad: tipoactividadseleccionada,
                    nombre_actividad: user.nombre_actividad,
                    descripcion_actividad: user.descripcion_actividad,
                    fecha_actividad: user.fecha_actividad,
                })
                .eq('id_actividad', user.id_actividad);

            if (error) {
                throw error;
            }

            showNotification({
                title: 'Actividad actualizada',
                message: 'La actividad se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la actividad:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la actividad.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());

            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_actividades')
                    .insert({
                        id_trato: tratoseleccionado,
                        id_estatus_actividad: estatusactividadseleccionada,
                        id_tipo_actividad: tipoactividadseleccionada,
                        nombre_actividad: user.nombre_actividad,
                        descripcion_actividad: user.descripcion_actividad,
                        fecha_actividad: user.fecha_actividad,
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Actividad agregada',
                    message: 'La actividad ha sido agregada con éxito',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdate();
            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar actividad:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar actividad.',
                color: 'red',
            });
        }
    };

    //para filtrar tratos
    const handleTratoFilterChange = (event) => {
        setTratosFilter(event.target.value);
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{ display: 'inline', flexWrap: 'wrap', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }} className='caja-20p'>
                <Card className='CardTablas'>
                    <Text className='caja-400'><strong>Empresa:</strong> <Text fs="italic" >{empresaSeleccionada}</Text></Text>
                    <Text className='caja-400'><strong>Trato:</strong> <Text fs="italic" >{nombreTratoSeleccionado}</Text></Text>
                    <Text className='caja-400'><strong>Descripción de trato:</strong> <Text fs="italic" >{descripcionTratoSeleccionado}</Text></Text>
                </Card>
                <Accordion defaultValue="">
                    <Accordion.Item value="catalogo">
                        <Accordion.Control className="AccordionControl"
                        >
                            Método Sandler
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel" >

                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultValue="">
                    <Accordion.Item value="catalogo">
                        <Accordion.Control className="AccordionControl"
                        >
                            Contactos relacionados
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel" >
                            <List spacing="xs" size="sm" center>
                                <List listStyleType="inherit">
                                    {contactosRelacionados.map(contacto => (
                                        <div key={contacto.id_trato_contacto} className="contacto-item" style={{ padding: '10px 0' }}>
                                            {/* Aquí puedes mostrar los detalles del contacto */}
                                            <Text weight={500} size="sm">{contacto.cat_contactos.nombre_contacto}</Text>
                                            <Group spacing={5}>
                                                <IconMail size={16} />
                                                <Text size="sm" color="dimmed" style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}>{contacto.cat_contactos.correo_contacto}</Text>
                                            </Group>
                                            <Group spacing={5}>
                                                <IconPhone size={16} />
                                                <Text size="sm" color="dimmed" style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}>{contacto.cat_contactos.celular_contacto}</Text>
                                            </Group>
                                            <Group spacing={5}>
                                                <IconId size={16} />
                                                <Text size="sm" color="dimmed" style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}>{contacto.cat_contactos.puesto_contacto}</Text>
                                            </Group>
                                            <Group spacing={5}>
                                                <IconBuilding size={16} />
                                                <Text size="sm" color="dimmed" style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}>{contacto.cat_contactos.cat_empresas.nombre_corto_empresa}</Text>
                                            </Group>

                                            <Divider style={{ margin: '5px 0' }} />
                                        </div>
                                    ))}
                                </List>
                            </List>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion defaultValue="">
                    <Accordion.Item value="catalogo">
                        <Accordion.Control className="AccordionControl"
                        >
                            Fuente
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel" >

                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>




            </div>
            <form onSubmit={handleSubmit} className='caja-75p'>
                <Card className='CardTablas'>
                    <Group>
                        <div className='FiltroActividades' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', width: '100%' }}>
                            <TextInput
                                disabled
                                name="id_actividad"
                                label="ID"
                                className='caja-150'
                                onChange={handleChange}
                                value={user.id_actividad}
                                style={{ flex: '1 1 30%' }}
                            />
                            <TextInput
                                label="Filtrar empresas"
                                value={tratosFilter}
                                onChange={handleTratoFilterChange}
                                className='caja-150'
                                disabled={formMode === 'edit'}
                                style={{ flex: '1 1 30%' }}
                            />
                            <NativeSelect
                                label="Trato"
                                name="id_trato"
                                placeholder="Seleccione Trato"
                                className='caja-150'
                                value={tratoseleccionado}
                                onChange={handleChangeSelect}
                                disabled={formMode === 'edit'}
                                style={{ flex: '1 1 30%' }}
                            >
                                {formMode === 'add' && <option value="">Seleccione Valor</option>}
                                {listadotratos.map((tipo) => (
                                    <option key={tipo.id_trato} value={tipo.id_trato}>
                                        {tipo.nombre_trato}
                                    </option>
                                ))}
                            </NativeSelect>

                            <DateTimePicker
                                label="Fecha y hora"
                                placeholder="Selecciona fecha y hora"
                                className='caja-150'
                                value={convertToDate(user.fecha_actividad)}
                                onChange={(date) => setUser({ ...user, fecha_actividad: date ? date.toISOString() : "" })}
                                style={{ flex: '1 1 30%' }}
                            />
                            <TextInput
                                label="Nombre de la Actividad"
                                name="nombre_actividad"
                                placeholder="Nombre de la Actividad"
                                className='caja-150'
                                value={user.nombre_actividad}
                                onChange={handleChange}
                                style={{ flex: '1 1 30%' }}
                            />
                            <NativeSelect
                                label="Estatus"
                                name="id_estatus_actividad"
                                placeholder="Seleccione un estatus"
                                className='caja-150'
                                value={estatusactividadseleccionada}
                                onChange={handleChangeSelect}
                                style={{ flex: '1 1 30%' }}
                            >
                                {formMode === 'add' && <option value="">Seleccione Valor</option>}
                                {listadoestatusactividad.map((tipo) => (
                                    <option key={tipo.id_estatus_actividad} value={tipo.id_estatus_actividad}>
                                        {tipo.nombre_estatus_actividad}
                                    </option>
                                ))}
                            </NativeSelect>

                            <NativeSelect
                                label="Tipo"
                                name="id_tipo_actividad"
                                placeholder="Seleccione un tipo"
                                className='caja-150'
                                value={tipoactividadseleccionada}
                                onChange={handleChangeSelect}
                                style={{ flex: '1 1 30%' }}
                            >
                                {formMode === 'add' && <option value="">Seleccione Valor</option>}
                                {listadotiposactividades.map((tipo) => (
                                    <option key={tipo.id_tipo_actividad} value={tipo.id_tipo_actividad}>
                                        {tipo.nombre_tipo_actividad}
                                    </option>
                                ))}
                            </NativeSelect>
                        </div>
                    </Group>
                    <Group>
                        <Textarea
                            mt={'10px'}
                            label="Descripción de la Actividad"
                            name="descripcion_actividad"
                            placeholder="Descripción de la Actividad"
                            styles={{
                                input: {
                                    backgroundColor: '#FCF3C2' // Color amarillo
                                }
                            }}
                            className='caja-notas'
                            autosize
                            minRows={4}
                            value={user.descripcion_actividad}
                            onChange={handleChange}
                            style={{ width: '100%' }}
                        />
                    </Group>
                    <Group justify='center' mt={20}>
                        <Button type="submit" color="blue">Guardar</Button>
                        <Button color="gray" onClick={() => setShowForm(false)}>Cancelar</Button>
                    </Group>
                </Card>
            </form>


        </div>
    )
}

export default ActividadesForm;
