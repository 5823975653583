import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Text, Group, Select, Button, NativeSelect, Collapse } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";



function FuenteForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    //const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_fuente: "",
        id_fuente: "" // Inicializar id_fuente como una cadena vacía

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_fuente: transactionData.nombre_fuente,
                id_fuente: transactionData.id_fuente

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateFuente = async () => {

        try {
            await supabase
                .from('cat_fuentes')
                .update({
                    nombre_fuente: user.nombre_fuente
                })
                .eq('id_fuente', user.id_fuente); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Fuente actualizada',
                message: 'La Fuente se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la Fuente:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la fuente.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('cat_fuentes')
                    .insert({
                        nombre_fuente: user.nombre_fuente
                    });
                    showNotification({
                        title: 'Fuente agregada',
                        message: 'La Fuente ha sido agregada con éxito',
                        color: 'green',
                    });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateFuente();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            console.error('Error al agregar/actualizar Fuente:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Fuente.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                            <TextInput
                                disabled
                                name="id_fuente"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '300px' }}
                                value={user.id_fuente}

                            />

                            <TextInput
                                name="nombre_fuente"
                                label="Nombre Fuente"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.nombre_fuente} // Asignar el valor del estado al campo
                                placeholder='Nombre Fuente'
                               
                            />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Fuente" : "Editar Fuente"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default FuenteForm;